.messageModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1000;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); 
}

.messageModalOverlay.show {
    opacity: 1;
}

.messageModal {
    position: absolute;
    /* top: 50%; */
    left: 0;
    /* transform: translateY(-50%); */
    /* background: #1C1C1C; */
    /* border-radius: 12px; */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    /* padding: 37px 24px 30px; */
    opacity: 0;
    margin: 0 20px;
    width: calc(100% - 40px);
    /* transition: opacity 0.3s ease; */

    bottom: -100%;
    transform: translateY(100%); 
    transition: all 0.5s ease;
    overflow: hidden;

}

.messageModal .header {
    position: relative;
    display: flex;
    background: #656565;
    color: #1C1C1C;
    justify-content: space-between;
    align-items: center;
}

.messageModal .header .headerTitle {
    padding: 2px 12px;
    font-size: 14px;
}

.messageModal .header .titleRight {
    display: flex;
}


.messageModal.show {
    opacity: 1;
    transform: translateY(50%);
    bottom: 50%;
    left: 0;
}

.messageModal.hide {
    opacity: 0;
    transform: translateY(100%); 
    left: 0;
}


.messageModal_content {
    /* text-align: center; */
    font-weight: 500;
    line-height: 135%;
    color: #b4b4b4;
    color: #656565;
    font-size: 12px;
    background: rgb(28, 28, 28);
    padding: 20px 12px 30px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.messageModal__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    gap: 10px;

}

.button{
    border-radius: 8px;
    padding: 12px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    flex: 1;
}

.button.accept {
    /* background: rgb(31, 115, 86, 0.2); */
    background: #1D2D28;
    border: 1px solid #1F7356;
    color: #1F7356;
}


.button.decline {
    /* background: rgb(31, 115, 86, 0.2); */
    background: #3A2626;
    border: 1px solid #B15050;
    color: #B15050;
}


.button.info {
    /* background: rgb(31, 115, 86, 0.2); */
    background: #2B2B2B;
    border: 1px solid #656565;
    color: #656565;
}

.closeButton {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1C1C1C;
    color: #656565;
    border-bottom: 1px solid #656565;
    border-left: 1px solid #656565;
    box-sizing: border-box;
    line-height: 1.6;
    font-size: 14px;

  }



   /* Particle animation background */
   .particleContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
  }
  
  .particle {
    position: absolute;
    background: rgba(31, 115, 86, 0.3);
    border-radius: 50%;
    pointer-events: none;
    opacity: 0.3;
    transition: all 3s ease;
    animation: float 15s infinite alternate;
    box-shadow: 0 0 10px rgba(31, 115, 86, 0.5);
  }
  
  @keyframes float {
    0% {
      transform: translateY(0) translateX(0) scale(1);
      opacity: 0.2;
    }
    50% {
      transform: translateY(-20px) translateX(10px) scale(1.5);
      opacity: 0.4;
    }
    100% {
      transform: translateY(0) translateX(-10px) scale(1);
      opacity: 0.2;
    }
  }