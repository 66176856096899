:root {
    /* Базовые цвета палитры */
    --color-white: #fff;
    --color-black: #000;
    --color-blue: #0097ec;
    --color-green: #7ed321;
    --color-gray-100: #f5f5f5;
    --color-gray-200: #e5e5e5;
    --color-gray-300: #d4d4d4;
    --color-gray-400: #b4b4b4;
    --color-gray-500: #9f9f9f;
    --color-gray-600: #939393;
    --color-gray-700: #6d6d6d;
    --color-gray-800: #383838;
    --color-gray-900: #272727;

    /* Прозрачные цвета */
    --color-blue-10: rgba(0, 151, 236, 0.1);
    --color-blue-50: rgba(0, 151, 236, 0.5);
    --color-white-10: rgba(255, 255, 255, 0.1);
    --color-white-60: rgba(255, 255, 255, 0.6);
    --color-green-50: rgba(76, 175, 80, 0.5);

    /* Цвета компонентов */

    /* Общие цвета текста */
    --text-primary-color: var(--color-white);
    --text-secondary-color: var(--color-gray-500);
    --text-tertiary-color: var(--color-gray-400);
    --text-faded-color: var(--color-white-60);

    /* Компоненты информационной секции */
    --info-section-bg-color: var(--bg-secondary-color);

    /* Компонент карточки */
    --card-bg-color: var(--color-gray-900);
    --card-text-color: var(--color-white);
    --card-secondary-text-color: var(--color-gray-400);

    /* Компонент кнопки */
    --button-bg-color: var(--primary-color);
    --button-text-color: var(--color-white);

    /* Компонент стрелки слайдера */
    --arrow-bg-color: var(--color-white-10);
    --arrow-color: var(--color-gray-600);

    /* Компонент денежной суммы */
    --money-text-color: var(--color-blue);
    --money-bg-color: var(--color-blue-10);

    /* Компонент премиум иконки */
    --premium-icon-color: var(--primary-color);
    --premium-icon-inactive-color: var(--color-gray-400);

    /* Компонент пулла */
    --pool-icon-bg-color: var(--color-gray-400);
    --pool-icon-text-color: var(--bg-secondary-color);

    /* Компонент blob кнопки */
    --blob-bg-color: var(--primary-color);
    --blob-success-color: var(--color-green);
    --blob-shadow-color: var(--color-blue-50);
    --blob-success-shadow-color: var(--color-green-50);
    --blob-checkmark-color: var(--color-white);
}

.infoSection {
    display: flex;
    flex-direction: column;
    /* padding: 20px 16px; */
    /* padding-bottom: 200px; */
    border-radius: 16px;
    background-repeat: no-repeat;
    background-size: cover;
    background: var(--info-section-bg-color);
    padding-bottom: 20px;
}

.infoSectionContainer {
    margin: 40px 16px 24px;
}

.header {
    padding: 18px 16px 10px;
    display: flex;
    justify-content: space-between;
}

.claimButton {
    padding: 8px 42px !important;
    border-radius: 8px !important;
    font-size: 14px !important;

    width: fit-content !important;
    height: fit-content !important;
}

.burnedButton {
    /* color: rgba(255, 0, 43, 1); */
    color: #999;
    /* background: rgba(255, 0, 43, 0.1); */
    background: none;
    font-weight: 400;
    font-size: 12px;
    padding: 8px 0;
    margin-top: 5px;
}

.booster {
    padding: 14px 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.miningInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.subheader {
    font-weight: 700;
    font-size: 12px;
    color: var(--text-secondary-color);
}

.subheader .separator {
    margin: 0 4px;
}


.miningInfo__mined {
    font-weight: 700;
    font-size: 20px !important;
    color: var(--text-primary-color);
    font-size: 14px;
}

.miningInfo__mined span {
    font-size: 12px;

}

.miningInfo__ghs {
    font-weight: 400;
    font-size: 14px;
    color: var(--text-faded-color);
}

.booster_line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.booster_line .left {
    display: flex;
    gap: 10px;
    align-items: center;
}

.booster_line .right {
    display: flex;
    gap: 16px;
    align-items: center;
}


.booster__percent {
    font-weight: 700;
    font-size: 12px;
    color: var(--text-secondary-color);
    text-align: right;
}

.booster__receive {
    font-weight: 700;
    font-size: 12px;
    width: 60px;
    color: var(--text-primary-color);
    text-align: right;
}

.booster__name {
    font-weight: 700;
    font-size: 12px;
    color: var(--text-secondary-color);
}

.booster__percent {
    font-weight: 700;
    font-size: 12px;
    color: var(--text-secondary-color);
}

.booster__value {
    font-weight: 700;
    font-size: 12px;
    color: var(--text-primary-color);
}

.infoCard {
    background: var(--card-bg-color);
    display: flex;
    gap: 7px;
    border-radius: 8px;
    padding: 14px 12px;
    align-items: center;
}

.infoCard_image {
    height: 60px;
}

.infoCard_content {}

.infoCard__name {
    font-weight: 400;
    font-size: 14px;
    color: var(--card-text-color);
}

.infoCard__name span {
    font-size: 12px;
    color: var(--card-secondary-text-color);
}

.infoCard__ghs {
    font-weight: 400;
    font-size: 10px;
    color: var(--card-secondary-text-color);
}

.infoCard__money {
    margin-top: 6px;

    font-weight: 500;
    font-size: 12px;
    color: var(--money-text-color);

    border-radius: 4px;
    padding: 2px 4px;
    background: var(--money-bg-color);
}

.infoCard__money span {
    font-size: 10px;
}

.sliderLeft {
    animation: slideRight 0.6s normal;
    opacity: 1;
}

.sliderRight {
    animation: slideLeft 0.6s normal;
    opacity: 1;
}

@keyframes slideLeft {
    0% {
        opacity: 1;
    }

    50% {
        transform: translateX(-100%);
        opacity: 0;
    }

    50.01% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slideRight {
    0% {
        opacity: 1;
    }

    50% {
        transform: translateX(100%);
        opacity: 0;
    }

    50.01% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

.info_buttons {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 27px;
}

.total__span {
    font-size: 12px;
    font-weight: 500;
    color: var(--text-tertiary-color);
}

.cardSlider {
    padding: 0 40px;
    position: relative;
    overflow: hidden;
}

.arrow {
    height: 21px;
    width: 21px;
    background: var(--arrow-bg-color);
    border-radius: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--arrow-color);
    cursor: pointer;
    position: absolute;
}

.arrowLeft {
    left: 11px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
}

.arrowRight {
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
}


.premiumIcon {
    position: relative;
    width: 22px;
    height: 22px;
}

.premiumIcon .notActiveBg {
    color: var(--premium-icon-inactive-color) !important;
}

.premiumIcon .notActiveContent {
    color: var(--bg-secondary-color);
}

.premiumIcon .bg {
    width: 100%;
    height: 100%;
    color: var(--premium-icon-color);
}

.premiumIcon .content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    transform: translateX(-50%) translateY(-50%);
}


.pool__icon {
    border-radius: 22px;
    width: 22px;
    height: 22px;
    background: var(--pool-icon-bg-color);
    color: var(--pool-icon-text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

/* --------------------------------------------------------------------------------------------------------------- */
.storeButton {
    font-weight: 700;
    border-radius: 10px;
    padding: 12px 20px;
    flex-shrink: 0;
    width: fit-content;
    flex: 1;
}


.filtersButton {
    font-weight: 400;
    font-size: 16px;
    color: var(--button-text-color);
    gap: 6px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.timer {
    font-size: 14px;
    text-align: center;
    margin-top: 4px;
    color: var(--text-tertiary-color);
}

.collectButton {
    display: flex;
    gap: 4px;
    align-items: baseline;
}

.blob {
    padding: 0 !important;
    width: fit-content !important;
    position: absolute !important;
    /* width: 40px !important; */
    height: 40px !important;
    background: var(--blob-bg-color);
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;

    transform: translateY(-50%) translateX(-50%) scale(1);
    left: 50%;

    box-shadow: 0 0 0 0 var(--color-blue);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: translateY(-50%) translateX(-50%) scale(0.95);
        left: 50%;
        box-shadow: 0 0 0 0 var(--blob-shadow-color);
    }

    70% {
        transform: translateY(-50%) translateX(-50%) scale(1);
        left: 50%;
        box-shadow: 0 0 0 10px rgba(0, 151, 236, 0);
    }

    100% {
        transform: translateY(-50%) translateX(-50%) scale(0.95);
        left: 50%;
        box-shadow: 0 0 0 0 rgba(0, 151, 236, 0);
    }
}

.blob {
    padding: 0 !important;
    width: fit-content !important;
    position: absolute !important;
    width: 40px !important;
    height: 40px !important;
    background: var(--blob-bg-color);
    border-radius: 50% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;


    transform: translateY(-50%) translateX(-50%) scale(1);
    box-shadow: 0 0 0 0 var(--color-blue);
    animation: pulse 2s infinite;
    transition: background-color 0.4s ease-in-out;

    /* font-size: 12px; */
    /* border-radius: 4px !important; */
    /* padding: 0 6px !important; */

}

.blobContent {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 4px !important;
}



@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(80deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.animated {
    background: var(--blob-success-color) !important;
    /* Цвет при анимации */
    animation: pulse-active 2s infinite;
    /* Новый цвет пульса */
}

.checkmark {
    width: 20px;
    height: 20px;
    visibility: hidden;
    transform: translateY(3px) translateX(-1px);
}

.show {
    visibility: visible;
}

.checkmarkPath {
    stroke: var(--blob-checkmark-color);
    stroke-width: 7;
    stroke-linecap: round;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: drawCheck 0.6s 0.5s forwards;
    /* Галочка рисуется после 0.5с */
}

@keyframes pulse-active {
    0% {
        transform: translateY(-50%) translateX(-50%) scale(0.95);
        box-shadow: 0 0 0 0 var(--blob-success-shadow-color);
        left: 50%;
    }

    70% {
        transform: translateY(-50%) translateX(-50%) scale(1);
        box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
        left: 50%;
    }

    100% {
        transform: translateY(-50%) translateX(-50%) scale(0.95);
        box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
        left: 50%;
    }
}

@keyframes drawCheck {
    to {
        stroke-dashoffset: 0;
    }
}

/* .changeCoin {
    background: var(--card-bg-color);
    font-size: 10px !important;
    padding: 2px 4px;
    border-radius: 4px;
    color: #0097ec;
} */



/* Модалка сгораний  */
/* ------------------------------------------------------ */
.burns_container_wrapper::after {
    content: '';
    width: 100%;
    height: 40px;
    bottom: 0;
    position: absolute;

    background: rgba(11, 11, 11, 0.5);
    background: linear-gradient(360deg, rgba(11, 11, 11, 1) 30%, rgba(11, 11, 11, 0) 100%);
}

.burns_container_wrapper {
    flex: 1;
    position: relative;
    display: flex;
    margin-bottom: 12px;
    overflow: hidden;


}

.burns_container {
    padding-bottom: 60px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 8px;
flex: 1;


}

.burns_container::-webkit-scrollbar{
    display: none;
}



.burn {
    display: flex;
    background: rgba(255, 0, 51, 0.05);
    padding: 8px 12px;
    border-radius: 8px;
    gap: 12px;
}

.burn_left {
    color: rgba(255, 0, 51, 1);
}

.burn_right {
    font-size: 14px;
    color: rgba(255, 0, 51, 0.6);

}

.burn_buttons {
    display: flex;
    padding: 0 10%;
}

.burn_button__close {
    padding: 16px 16px;
    font-size: 14px;
    border-radius: 50px;
}

.noBurns{
    color: #999999;
    background: rgba(255, 255, 255, 0.05);
    padding: 12px 24px;
    font-size: 14px;
    border-radius: 8px;
    margin: 0 auto;
    width: 100%;
    text-align: center;

}