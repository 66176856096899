.timer {
    padding: 6.5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    font-size: 12px;
    text-align: center;
}

.time {
    width: 53px;
}

.textLine {
    display: flex;
    gap: 4px;
}