.modalContent{
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
}

.stakingData {
    margin-bottom: 40px;
}

.stakingInput {
    margin-bottom: 80px;
}

.modalBalance {
    font-weight: 400;
    font-size: 16px;
    line-height: 89%;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 10px;
}