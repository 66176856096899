.lotteryItemS {
  display: flex;
  gap: 6px;
  
}

.lotteryItem {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.lotteryItem:first-child {
  margin-top: 30px;
}

.lotteryItem:last-child {
  margin-top: 40px;
}

.lotteryItem::after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: '';
  height: 40px;
  width: 40px;
  background: #A100EC;
  z-index: -1;

  filter: blur(20px);
  -webkit-filter: blur(20px);

  transform: translateX(-50%) translateY(-50%);
  opacity: 0.5;
}


@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.lotteryItem {
  position: relative;
  width: fit-content;
  height: fit-content;
  animation: float 3s ease-in-out infinite;
}







.container {
  display: flex;
  align-items: center;
flex-shrink: 0;



  background-image: url("data:image/svg+xml,%3Csvg width='49' height='46' viewBox='0 0 49 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' opacity='0.2' clip-rule='evenodd' d='M25.118 28.379L30.6299 26.9021C31.4466 26.6833 31.9295 25.8371 31.7084 25.0121L31.199 23.1108C31.1387 22.8858 30.9092 22.7518 30.6865 22.8115C30.1668 22.9508 29.6314 22.6381 29.4907 22.113C29.3501 21.588 29.6574 21.0495 30.1771 20.9103C30.3998 20.8506 30.5315 20.6198 30.4712 20.3948L29.9618 18.4936C29.7407 17.6686 28.8995 17.1772 28.0827 17.396L22.5708 18.8729L25.118 28.379ZM21.7642 19.089L19.4788 19.7014C18.6621 19.9203 18.1792 20.7665 18.4003 21.5915L18.9097 23.4927C18.97 23.7177 19.1994 23.8517 19.4222 23.792C19.9419 23.6528 20.4773 23.9655 20.6179 24.4905C20.7586 25.0155 20.4513 25.554 19.9316 25.6933C19.7089 25.753 19.5772 25.9838 19.6375 26.2087L20.1469 28.11C20.368 28.935 21.2092 29.4264 22.026 29.2076L24.3114 28.5952L21.7642 19.089Z' fill='url(%23paint0_radial_1820_8744)'/%3E%3Cdefs%3E%3CradialGradient id='paint0_radial_1820_8744' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(28.6625 18.7317) rotate(128.012) scale(12.2489 11.839)'%3E%3Cstop stop-color='%234D9EF1'/%3E%3Cstop offset='1' stop-color='%234E0FC6'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E%0A");

  background-size: 40px 40px;
  -webkit-animation: bscale 10s infinite linear;
  animation: bscale 10s infinite linear;

  padding: 18px 12px;
  position: relative;

  border-radius: 8px;
  overflow: hidden;
}

.container::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(270deg, rgba(78, 21, 200, 0.6) 0%, rgba(78, 21, 200, 0.1) 100%);
  z-index: -1;
}

.iconContainer {
  min-width: 35vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  /* width: 35vw; */
  width: 45%;
  /* animation: rotate 1s infinite linear; */
  animation: shake 5s infinite;
  animation: jump-shaking 5s infinite;

}

@keyframes jump-shaking {

  0%,
  80% {
    transform: translateX(0)
  }

  83% {
    transform: translateY(-9px)
  }

  86% {
    transform: translateY(-9px) rotate(17deg)
  }

  89% {
    transform: translateY(-9px) rotate(-17deg)
  }

  92% {
    transform: translateY(-9px) rotate(17deg)
  }

  95% {
    transform: translateY(-9px) rotate(-17deg)
  }

  98%,
  100% {
    transform: translateY(0) rotate(0)
  }
}

@keyframes shake {

  0%,
  94% {
    transform: rotate(0deg);
  }

  94.1% {
    transform: rotate(45deg);
  }

  94.2% {
    transform: rotate(-45deg);
  }

  94.3% {
    transform: rotate(45deg);
  }

  94.4% {
    transform: rotate(-45deg);
  }

  94.5%,
  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes bscale {
  0% {
    background-position: 0px 0px;
  }

  100% {
    background-position: 100% 100%;
  }
}


@keyframes bscale {
  0% {
    background-position: 0px 0px;
  }

  100% {
    background-position: 100% 100%;
  }
}

.title {
  font-size: 14px;
  color: #fff;
}

.description {
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 20px;
  opacity: 0.6;
}

.control {
  display: flex;
  align-items: center;
  gap: 12px;
}

.description {
  font-size: 12px;

}

.purchase {
  padding: 6px 13px;
  width: fit-content;
  font-size: 12px;
}