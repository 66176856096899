.mainContainer{
    min-height: 100vh;
    background: linear-gradient(176deg, rgba(255, 183, 0, 0.15) 0%, rgba(0, 0, 0, 0) 40%);
}
.main {
    padding: 24px 16px;
    display: flex;
    flex-direction: column;



    overflow-y: scroll;
    max-height: calc(100vh - 89.4px);
}


.main::-webkit-scrollbar {
    display: none;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 50px;
}

.title {
    color: #FFB700;
    display: flex;
    align-items: center;
    gap: 8px;
}

.title div {
    font-weight: 400;
    font-size: 22px;
    line-height: 75%;
    text-align: center;
    color: #ffb700;
}

.description {
    font-weight: 500;
    font-size: 13px;
    line-height: 135%;
    color: #999;
}

.logoContainer {
    border: 0.50px solid #484849;
    border-radius: 8px;
    padding: 4px;
    width: 69px;
    height: 69px;
}


.staking{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.headerStaking{
    text-transform: uppercase;
    color: var(--text-secondary-color);
    font-size: 12px;
    margin-bottom: 10px;
}

.buttonsContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 24px 16px;
    background: rgba(0, 0, 0, 0.70);
}

.stakingOptionsContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* .buttonsContainer .primaryButton{
    : 0 16px;
} */

.backLink {
    text-decoration: none;
}


.loader {
    border: 2px solid rgba(255, 255, 255, 0); 
    border-top: 2px solid white; 
    border-left: 2px solid white; 
    border-bottom: 2px solid white; 
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }