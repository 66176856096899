.user__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 100%;

  background: #0e1112;
  overflow: hidden;
  position: relative;
}

.image {
  object-fit: cover;
  object-position: center;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  
}


.spinnerContainer {
  width: 100%;
  height: 100%;
  background: #0e1112;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-left-color: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

.defaultAvatar{
  font-size: 10px
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}