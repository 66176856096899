.main {
    padding: 24px 16px 100px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: scroll;

}

.main::-webkit-scrollbar {
    display: none;
}

.minexDataIcon {
    color: var(--primary-color);
}

.titleBar {
    display: flex;
    align-items: center;
    gap: 4px;
}

.titleBar .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #fff;
}

.settings_container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.settings_group .header {
    font-size: 12px;
    margin-bottom: 6px;
}

.settings_group .container {
    background: var(--bg-secondary-color);
    padding: 12px;
    border-radius: 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.parametrs {
    display: flex;
    gap: 6px;
}

.langButton {
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
}

.eng {
    background: #0095ec1e;
    color: var(--primary-color);
    font-weight: 500;
    border: 0.5px solid var(--primary-color)
}




.buttonsContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 24px 16px;
    background: rgba(0, 0, 0, 0.70);
}

/* .buttonsContainer .primaryButton{
    : 0 16px;
} */

.backLink {
    text-decoration: none;

}

.buttonsLine {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.secondaryButton {
    background: rgb(0, 149, 235, 0.15);
    flex: 1;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 13px;
    
    display: flex;
    gap: 10px;
    align-items: center;
    border: 0.4px solid rgb(0, 149, 235, 0.45);
    color: var(--primary-color);

}

.secondaryButton .icon{
    height: 24px;
    width: 24px;
    border-radius: 4px;
    background: rgb(0, 149, 235, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.secondaryButton .icon svg{
    height: 16px;
    width: 16px;
}