:root {
  --primary-color: #0097EC;
  --primary-color-rgb: 0, 151, 236;

  --text-primary-color: #fff;
  --text-secondary-color: #b4b4b4;
  --bg-secondary-color: #171717;
}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  user-select: none;
  -webkit-user-select: none;
  /* Для Safari */
  -moz-user-select: none;
  /* Для Firefox */
  -ms-user-select: none;
  /* Для Internet Explorer */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100vh;
  height: 100vh;

  overflow: hidden;

}



* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  /* Отключает подсветку при нажатии */
  outline: none;
}

.spin-premium-icon {
  transform: rotate(20deg);
}


.rotating {
  animation: rotate 4s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}