.icon {
    animation: rotateAnimation 2s linear infinite;
}

@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(80deg);
    }

    100% {
        transform: rotate(0deg);
    }
}