.history {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
    flex: 1;
    overflow: scroll;
    position: relative;
    padding-bottom: 24px;

}

.historyContainer {
    display: flex;
    position: relative;



    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow: hidden;
    position: relative;
}

.historyContainer::before {
    content: '';
    width: 100%;
    height: 30px;
    background: linear-gradient(360deg, rgba(11, 11, 11, 1) 20%, rgba(11, 11, 11, 0.8) 50%, transparent 100%);
    ;
    position: absolute;
    bottom: 0;
    z-index: 1;

}

.historyContainer::-webkit-scrollbar,
.history::-webkit-scrollbar {
    display: none;
}

.history .item {
    display: flex;

    border-radius: 8px;
    padding: 12px 8px;
    gap: 12px;
    position: relative;
    border: 0;
    border-left: 1px;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-style: solid;
    background: #101010;

}



.pink {
    border-color: rgba(197, 0, 98, 0.9) !important;
}

.gray {
    border-color: rgba(77, 77, 77, 0.8) !important;
}

.yellow {
    border-color: rgba(255, 162, 0, 0.8) !important;
}

.blue {
    border-color: rgba(54, 58, 255, 0.8) !important;
}

.purple {
    border-color: rgba(131, 54, 255, 0.8) !important;
}

.red {
    border-color: rgba(255, 43, 31, 0.7) !important;
}

.history .item .title {
    font-size: 13px;
}

.history .item .title span {
    font-size: 12px;
    color: #b4b4b4;
}


.history .item .lbx {
    font-size: 13px;
}

.history .item .lbx span {
    font-size: 12px;
    color: #b4b4b4;
}

.history .item .date {
    font-size: 12px;
    color: #b4b4b4;
}

.history .item .date span {
    font-size: 12px;
    color: #b4b4b4;
}



.history .item .buttons {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 12px;
    bottom: 8px;
    align-items: flex-end;
    gap: 8px;
}

.history .item .buttons .button {
    font-size: 9px;
    width: fit-content;
    padding: 7px 13px;
    border-radius: 4px;
}

.history .item .buttons .claimed {
    font-size: 12px;
    width: fit-content;
    padding: 8px 16px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    color: #b4b4b4;
}

.left {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 70px;
}

.right {
    flex: 1;
    display: flex;
    gap: 2px;
    flex-direction: column;


    padding: 0 0 20px;
}

.premiumIconSVG {
    color: #0097ec;
}

.body img {
    height: 48px;
    width: 48px;
    object-fit: contain;
}