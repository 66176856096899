.commision .title {
    color: #b4b4b4;
    font-size: 12px;
    text-align: center;
    margin-top: 40px;
}

.commision .value {
    text-align: center;
    font-size: 48px;
    margin-top: 10px;

}

.commision .value span{
    font-size: 16px;
}

.commision .subTitle {
    font-size: 14px;
    text-align: center;
    color: #b4b4b4;
    margin-top: 4px;
}

.additional {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 6px;
}

.additional_coin {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}


.commision .button {
    padding: 12px 20px;
    background: #E7E0AE;
    color: black;
    margin: 40px auto 0;
    font-size: 14px;
}
