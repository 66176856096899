.mainContainer {
    background: linear-gradient(176deg, #f7800138 0%, rgba(0, 0, 0, 0) 50%);
    /* background: linear-gradient(180deg, rgba(255, 157, 0, 0.22) 0%, rgba(0, 0, 0, 0.22) 100%); */
    /* background: linear-gradient(180deg, rgba(255, 157, 0, 0.16) 0%, rgba(250, 154, 0, 0.16) 4.7%, rgba(244, 150, 0, 0.16) 8.9%, rgba(237, 146, 0, 0.16) 12.8%, rgba(229, 141, 0, 0.16) 16.56%, rgba(220, 136, 0, 0.16) 20.37%, rgba(209, 129, 0, 0.16) 24.4%, rgba(197, 121, 0, 0.16) 28.83%, rgba(182, 112, 0, 0.16) 33.84%, rgba(165, 102, 0, 0.16) 39.6%, rgba(145, 90, 0, 0.16) 46.3%, rgba(123, 76, 0, 0.16) 54.1%, rgba(98, 60, 0, 0.16) 63.2%, rgba(69, 42, 0, 0.16) 73.76%, rgba(36, 22, 0, 0.16) 85.97%, rgba(0, 0, 0, 0.16) 100%); */
    /* ef7a0045 */
    height: 100vh;
    max-height: 100vh;
}

.main {
    height: 100vh;
    max-height: 100vh;

    padding: 24px 16px;
    display: flex;
    flex-direction: column;

    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(100vh - 89.4px);
}

.main::-webkit-scrollbar {
    display: none;
}

.header {
    position: relative;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.title {
    z-index: 1;
    background: rgba(247, 128, 1, 0.01);
    backdrop-filter: blur(3px);
    font-size: 24px;
    font-weight: 600;
}

.shop {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

/* Star Effects */
.star {
    position: absolute;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    fill: linear-gradient(180deg, #ffb700 0%, #efa200 25%, #f87900 50%, #fb6f00 75%, #ff5e00 100%);
    transition: 0.2s all ease;
}

.star_1 {
    bottom: 0;
    left: 40%;
    height: 16px;
    transform: rotate(-61.84deg);
    opacity: 1;
    animation-name: flyRight1;
    animation-duration: 5s;
    animation-delay: -1s;
}

.star_2 {
    top: -16px;
    left: 45%;
    height: 14px;
    transform: rotate(-31.21deg);
    animation-name: flyRight1;
    animation-duration: 6s;
    animation-delay: -5s;
}

.star_3 {
    bottom: 0;
    left: 53%;
    height: 11px;
    transform: rotate(-33.06deg);
    animation-name: flyRight1;
    animation-duration: 4s;
    animation-delay: -2s;
}

.star_4 {
    top: 50%;
    left: 60%;
    height: 16px;
    transform: rotate(-33.06deg);
    opacity: 1;
    animation-name: flyRight1;
    animation-duration: 7s;
    animation-delay: -2.5s;
}

.star_5 {
    bottom: -10px;
    left: 70%;
    height: 20px;
    transform: rotate(-33.06deg);
    opacity: 1;
    animation-name: flyRight1;
    animation-duration: 5s;
    animation-delay: -4s;
}

.star_6 {
    top: 50%;
    left: 80%;
    height: 12px;
    transform: rotate(-33.06deg);
    animation-name: flyRight1;
    animation-duration: 6.5s;
    animation-delay: -3.5s;
}

.star_7 {
    top: 0;
    left: 90%;
    height: 12px;
    transform: rotate(-33.06deg);
    opacity: 1;
    animation-name: flyRight1;
    animation-duration: 5.5s;
    animation-delay: -2s;
}

/* Keyframes for Each Star */
@keyframes flyRight1 {
    0% {
        opacity: 1;
        left: -10%;

    }

    100% {
        transform: rotate(360deg);
        opacity: 0;
        left: 110%;

    }
}




/* ------------------------------ */

.star_b_1 {
    bottom: 10px;
    left: 40%;
    height: 15px;
    transform: rotate(-61.84deg);
    opacity: 1;
    animation-name: flyRight1_back;
    animation-duration: 5s;
    animation-delay: -2s;
}

.star_b_2 {
    top: -6px;
    left: 45%;
    height: 14px;
    transform: rotate(-31.21deg);
    opacity: 1;
    animation-name: flyRight1_back;
    animation-duration: 6s;
    animation-delay: -3s;
}

.star_b_3 {
    bottom: 3px;
    left: 53%;
    height: 11px;
    transform: rotate(-33.06deg);
    opacity: 1;
    animation-name: flyRight1_back;
    animation-duration: 4s;
    animation-delay: -1s;
}

.star_b_4 {
    top: 55%;
    left: 60%;
    width: 21px;
    height: 16px;
    transform: rotate(-33.06deg);
    opacity: 1;
    animation-name: flyRight1_back;
    animation-duration: 7s;
    animation-delay: -4s;
}

.star_b_5 {
    bottom: -10px;
    left: 70%;
    height: 16px;
    transform: rotate(-33.06deg);
    opacity: 1;
    animation-name: flyRight1_back;
    animation-duration: 5s;
    animation-delay: -2.5s;
}

.star_b_6 {
    top: 40%;
    left: 80%;
    height: 12px;
    transform: rotate(-33.06deg);
    opacity: 1;
    animation-name: flyRight1_back;
    animation-duration: 6.5s;
    animation-delay: -3.5s;
}

.star_b_7 {
    top: 4px;
    left: 90%;
    height: 12px;
    transform: rotate(-33.06deg);
    opacity: 1;
    animation-name: flyRight1_back;
    animation-duration: 5.5s;
    animation-delay: -2s;
}

/* Keyframes for Each Star */
@keyframes flyRight1_back {
    0% {
        transform: rotate(0deg);
        opacity: 1;
        left: 110%;
    }

    100% {
        transform: rotate(360deg);
        opacity: 0;
        left: -10%;
    }
}



/* ------- */
.buttonsContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 24px 16px;
    background: rgba(0, 0, 0, 0.70);
}

.stakingOptionsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* .buttonsContainer .primaryButton{
    : 0 16px;
} */

.backLink {
    text-decoration: none;
}