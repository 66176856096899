
  .show {
    opacity: 1;
    visibility: visible;
  }
  
  .circle {
    width: 60px;
    height: 60px;
    position: relative;
  }
  
  .checkmark {
    width: 100%;
    height: 100%;
    /* transform: rotate(-90deg); */
  }
  
  .checkmarkCircle {
    stroke: #4caf50; /* Зелёный цвет */
    stroke-width: 2;
    stroke-dasharray: 157; /* 2 * π * r (r=25) */
    stroke-dashoffset: 157;
    animation: fillCircle 0.6s forwards;
  }
  
  .checkmarkPath {
    stroke: #4caf50;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: drawCheck 0.6s 0.6s forwards; /* Начинается после заполнения круга */
  }
  
  @keyframes fillCircle {
    to {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes drawCheck {
    to {
      stroke-dashoffset: 0;
    }
  }