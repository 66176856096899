.main {
    overflow-y: scroll;
    z-index: 0;
    display: flex;
    flex-direction: column;

    padding: 12px 16px 100px;
    max-height: calc(100vh - 89.4px);
}

.main::-webkit-scrollbar {
    display: none;
}

.subHeader {
    font-size: 16px;
    margin: 20px 0 6px;
}

.minersCount {
    background: rgb(0, 151, 236, 0.2);
    color: #0097ec;
    display: inline-flex;
    align-items: center;
    gap: 4px;
    padding: 2px 8px;
    border-radius: 6px;
    font-size: 14px;
}



.miners {
    background: var(--bg-secondary-color);
    border-radius: 8px;
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.miner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nameWithPosition {
    display: flex;
    align-items: center;
    gap: 6px;
}

.nameWithPosition .position {
    font-size: 12px;
    color: var(--text-secondary-color);
}


.nameWithPosition .name {
    font-size: 12px;
    color: var(--text-secondary-color);
}

.miner .ths {
    font-size: 12px;
    padding: 0 8px 0 0;
    color: var(--text-secondary-color);
    text-align: right;
    flex: 1;
}

.premiumIconOutline {
    color: #0097ec;
}

.premiumIcon {
    position: relative;
    width: 16px;
    height: 16px;
}

.premiumIcon .bg {
    width: 100%;
    height: 100%;
    color: var(--primary-color);

}

.premiumIcon .notActiveBg {
    color: #b4b4b4 !important;
}

.premiumIcon .notActiveContent {
    color: var(--bg-secondary-color);
}

.premiumIcon .content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    transform: translateX(-50%) translateY(-50%);

}

/* -------------------- */

.poolButtons {
    display: flex;

    gap: 10px;
}

.leaveButton {
    background: var(--bg-secondary-color);
    color: #ffffff;
    border: 1px solid #8f8f8f;
    font-size: 14px;

}

.joinButton {
    background: rgba(var(--primary-color-rgb), 0.1);
    color: #0097ec;
    border: 1px solid #0097ec;
    font-size: 14px;
}

.subscribeButton {
    background: rgba(var(--primary-color-rgb), 0.1);
    color: #0097ec;
    border: 1px solid #0097ec;
    font-size: 14px;
}


.editButton,
.viewButton {
    width: 70px;
    padding: 0px;
}








.tabsNav {
    font-size: 14px;
}



.editSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.editRow {
    position: relative;
}

.editSave {
    position: absolute;
    right: 10px;
    top: 50%;
    width: fit-content;
    transform: translateY(-50%);
    font-size: 12px;
    height: 40px;
}

.pageHide {
    opacity: 0;
    transition: 0s;
}


.commision {}

.commision .title {
    color: #b4b4b4;
    font-size: 12px;
    text-align: center;
    margin-bottom: 40px;
}

.commision .value {
    text-align: center;
    font-size: 48px;
}

.commision .subTitle {}

.commision .value {
    margin-top: 40px;
}

.commision .button {
    padding: 12px 20px;
    background: #E7E0AE;
    color: black;
    margin: 0 auto;
    font-size: 14px;
}

.commision .subTitle {
    font-size: 14px;
    text-align: center;
    color: #b4b4b4;
    margin-top: 4px;
}

.lvlUo {
    padding: 40px 40px 0;
}

.lvlUo .upContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
    gap: 10px;
}

.lvlUo .upContainer .up_level {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.lvlUo .upContainer .up_level .lvlUp_lvl {
    font-size: 24px;
}

.lvlUo .upContainer .up_level .lvlUp_lvl span {
    font-size: 14px;
}

.lvlUo .upContainer .up_level .minersCount {
    font-size: 14px;
    justify-content: center;
}

.lvlUo .lvl {
    font-size: 60px;
    font-weight: 500;
    text-align: center;
}

.lvlUo .lvl span {
    font-size: 14px;
}

.lvlUo .button {
    padding: 12px 20px;
    background: #E7E0AE;
    color: black;
    margin: 0 auto;
    font-size: 14px;
}



.nav_scroll {
    display: flex;
    gap: 12px;
    margin-top: 12px;
    overflow-x: scroll;
    flex-shrink: 0;
    overflow-y: visible;
}

.nav_scroll::-webkit-scrollbar {
    display: none;
}

.nav_scroll_tab {
    font-size: 12px;
    background: #1c1c1e;
    transition: all .1s ease;
    padding: 8px 12px;
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 8px;
    color: #fff;
}

.nav_scroll_active_tab {
    background: #0097ec;

}