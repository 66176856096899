.mainContainer{
    background: linear-gradient(178deg, rgba(3, 149, 235, 0.2) 0%, rgba(0, 0, 0, 0) 50%);
    min-height: 100vh;
    max-height: 100vh;
}
.container {
    padding: 12px 16px 200px;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    position: relative;
    z-index: 0;
  
    display: flex;
    flex-direction: column;
}

.container::-webkit-scrollbar {
    display: none;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gif {
    max-width: 30vw;
    margin-top: 20px;
    min-width: 30vw;
    min-height: 30vw;
}

.pageHeader {
    font-weight: 500;
    font-size: 24px;
    line-height: 135%;
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 10px;
}

.usersTable {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 20px;
}

.user {
    background: var(--bg-secondary-color);
    padding: 12px 14px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.user__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 40px;
    width: 40px;
    height: 40px;
    background: rgba(0, 151, 236, 0.1);

}

.user__name {
    font-size: 16px;
}

.user__info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.user__dataLine {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.user__balance {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #FFE03B;

}

.user__ghs {
    display: flex;
    align-items: center;
    gap: 4px;
    background: #0095ec5c;
    color: var(--primary-color);
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: 600;
}

.filtersButton {
    font-weight: 400;
    font-size: 16px;

    color: #fff;
    gap: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.05);
    margin-top: 6px;
}

.personCard {
    margin-top: 20px;
}

.loader {
    border: 2px solid rgba(255, 255, 255, 0); 
    border-top: 2px solid white; 
    border-left: 2px solid white; 
    border-bottom: 2px solid white; 
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }