.boster {
    display: flex;
    align-items: center;
    gap: 12px;
}

.boosterHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 6px;
}

.boosterHeader_left {
    display: flex;
    align-items: center;
}

.boosterIconContainer {
    width: 60px;
    height: 60px;
    background: rgba(255, 255, 255, .1);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}


.boosterIcon {
    width: 42px;
    height: 42px;
}

.boosterTitle {
    font-size: 14px;
    font-weight: 600;
}

.boosterDescription {
    font-size: 12px;
    line-height: 135%;
    color: #b4b4b4;
    word-break: break-word;
    hyphens: auto;
}

.boosterButton {
    font-size: 12px;
    border-radius: 6px;
    padding: 6px 8px;
    border: none;
    background: rgba(255, 255, 255, .1);
    color: #fff;
    font-weight: 600;
}

.boosterButton.active {
    background: var(--primary-color);
}

.boosterButton.buyed {
    background-color: rgba(28, 157, 133, 0.4);
    color: #00ff59;
}