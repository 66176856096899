.container {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  /* margin: 0 0.25rem;  */
  background: linear-gradient(to bottom, var(--gradient-start), var(--gradient-end));
  border: 2px solid #121212;
  border-radius: 0.25rem;
  /* Добавлено при необходимости */
  overflow: hidden;
  /* Для корректного отображения внутренних элементов */

  flex-direction: column;
  font-size: 11px;
  gap: 6px;
}

/* .gradientBlue {
    --gradient-start: #60a5fa; 
    --gradient-end: #3b82f6;   
  } */

/*     
  .gradientPurple {
    --gradient-start: #a78bfa; 
    --gradient-end: #8b5cf6;   
  }
  
  .gradientPink {
    --gradient-start: #f472b519; 
    --gradient-end: #ec4899;   
  }
  
  .gradientRed {
    --gradient-start: #f87171; 
    --gradient-end: #ef4444;   
  }
  
  .gradientYellow {
    --gradient-start: #fbbf24; 
    --gradient-end: #f59e0b;   
  } */

.gradientBlue {
  --gradient-start: #121212;
  /* Tailwind blue-400 */
  --gradient-end: #0061ff96;
  /* Tailwind blue-600 */
}

.gradientPurple {
  --gradient-start: #121212;
  /* Tailwind purple-400 */
  --gradient-end: #8a5cf679;
  /* Tailwind purple-600 */
}

.gradientPink {
  --gradient-start: #121212;
  /* Tailwind pink-400 */
  --gradient-end: #ec489a61;
  /* Tailwind pink-600 */
}

.gradientRed {
  --gradient-start: #121212;
  /* Tailwind red-400 */
  --gradient-end: #ef44445c;
  /* Tailwind red-600 */
}

.gradientYellow {
  --gradient-start: #121212;
  /* Tailwind yellow-400 */
  --gradient-end: #f59f0ba0;
  /* Tailwind yellow-600 */
}

.gradientGray {
  --gradient-start: #121212;
  /* Tailwind yellow-400 */
  --gradient-end: #1c1c1c;
  /* Tailwind yellow-600 */
}


/* .winnerRing {
    box-shadow: 0 0 0 4px rgba(34, 197, 94, 0.5); 
  } */

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 5px;
  user-select: none;
  pointer-events: none;
}

.idLabel {
  position: absolute;
  top: 0.25rem;
  /* Аналог top-1 */
  left: 0.25rem;
  /* Аналог left-1 */
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.25rem;
  /* Аналог px-1 */
  border-radius: 0.125rem;
  /* Аналог rounded */
  font-size: 12px;
  /* Аналог text-xs */
  color: white;
}

.winnerOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: rgba(34, 197, 94, 0.2);  */
  pointer-events: none;
}

.premiumIconBg {
  color: #0097ec;
}