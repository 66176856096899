.mainContainer {
    background: linear-gradient(178deg, rgba(3, 149, 235, 0.2) 0%, rgba(0, 0, 0, 0) 50%);
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;

}

.container {
    min-height: 100vh;
    position: relative;
    z-index: 0;
    padding: 0 16px;
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat
}

.linkContainer {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.buttonsContainer {
    display: grid;
    grid-template-columns: 3fr 1fr;
    column-gap: 10px;
    margin-top: 20px;
}

.tabs {
    flex: 1;
    display: flex;
}

.contentContainer {
    min-height: 100%;
    max-height: 90vh;
    width: 100%;
    overflow-y: scroll;
    padding: 0 0 200px;
}

.contentContainer::-webkit-scrollbar {
    display: none;
}

.friendsContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
    /* margin-top: 50px; */
}

/* ------ */
.pageHeader {
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    color: #fff;

    margin: 40px 0 11px;
}

.pageDescription {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 22px;
}

.textButton {
    font-weight: 700;
    font-size: 18px;
    line-height: 56%;
    color: #35e7e5;
    margin: 0 auto;
    text-align: center;
}

.banners {
    margin-top: 20px;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.errorContainer {
    background: var(--bg-secondary-color);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.errorTitle {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 8px;
    margin-top: 20px;
}

.errorDescruption {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    line-height: 135%;
    color: #b4b4b4;
    margin: 0 20px 10px;
}

.duck {
    max-width: 25vw;
}


.navigationBlock {
    display: flex;
    /* background: red; */
    justify-content: space-between;
    align-items: center;
    color: #0097EC;
    font-weight: 600;
    margin: 20px 0;
}

.navigationBlock__right {
    transform: rotate(180deg);
}

.navigationBlock__left {}



/* 
.statsBanner {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 12px;

}

.statsBanner .item{
    background: var(--bg-secondary-color);
    flex: 1;
    border-radius: 8px;
    padding: 12px 16px;
}

.statsBanner .item .item__header {
    font-size: 11px;
    margin-bottom: 8px;
}

.statsBanner__container {
   
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 12px;
}

.statsBanner__container_icon{
    width: 28px;
    height: 28px;
    background: rgba(255,255,255,0.1);
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statsBanner .totalRewards{
    color: rgb(255, 224, 59);
}

.statsBanner .totalCount{
    color: #fff;
}

.statsBanner__container_icon .totalCount{
    color: #fff;
}

.statsBanner__container .totalRewards{
    color: rgb(255, 224, 59);
    background: rgba(255,224,59,0.1);
} */


.statsBanner {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 12px;

}

.statsBanner .item {

    flex: 1;
    border-radius: 8px;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.statsBanner .item .item__header {
    font-size: 11px;
    margin-bottom: 13px;
    color: #fff;
}

.statsBanner__container {

    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 12px;
}

.statsBanner__container_icon {
    width: 28px;
    height: 28px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statsBanner .totalRewards {
    color: rgb(255, 224, 59);
    font-size: 24px;
}

.statsBanner .totalCount {
    color: #fff;
}

.statsBanner__container_icon .totalCount {
    color: #fff;
}

.statsBanner__container .totalRewards {
    color: rgb(255, 224, 59);
    background: rgba(255, 224, 59, 0.1);
}





.statsBanner .item_button {
    font-size: 14px;
    padding: 7px 60px;
    width: fit-content;
    margin-top: 35px;
}


.statsBanner .item_total {
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 16px;
    font-weight: 400s;
    color: #fff;
}

.statsBanner .item_total span {
    font-size: 12px;
    font-weight: 600;
    color: #0097EC;
}



/* Начальное состояние (по умолчанию у контейнера opacity=0 нет,
   т.к. пока идёт loader, блок не рендерится, но можно сделать и иначе) */


  
  .friendsList {
    display: flex;
    flex-direction: column;
    gap: 6px;
    opacity: 0; /* изначально скрываем, если хотим чтобы она была невидима */
    transition: opacity 0.3s ease-in, transform 0.3s ease-in;
    transform: translateY(6px);
  }
  
  /* Когда загрузка закончилась и у нас fadeIn=true */
  .fadeInRefferals {
    opacity: 1;
    transform: translateY(0);
  }
  
