/* Общий оверлей – занимает весь экран и не перехватывает клики */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  z-index: 1000;
}

/* ===== Режим эмодзи ===== */
.particle {
  position: absolute;
  top: -10%; /* старт немного выше экрана */
  animation-name: fall;
  /* Значение animationTimingFunction можно переопределять inline */
  will-change: transform, opacity;
}

@keyframes fall {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(110vh);
    opacity: 0;
  }
}

/* ===== Режим конфетти ===== */
.confetti {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  overflow: hidden;
  pointer-events: none;
}

/* Стили для отдельных кусочков конфетти */
.confettiPiece {
  position: absolute;
  width: 6px;
  height: 12px;
  top: -10px;
  opacity: 0;
  /* Анимация падает один раз до низа экрана с вращением */
  animation-name: makeItRain;
  animation-timing-function: ease-out; /* будет переопределён inline */
  animation-iteration-count: 1;
}

@keyframes makeItRain {
  0% {
    transform: translateY(0) rotate(var(--start-rotate));
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) rotate(calc(var(--start-rotate) + var(--rotate-amount)));
    opacity: 0;
  }
}