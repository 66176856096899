.container {
    display: flex;
    gap: 12px;
    margin-top: 12px;
    border: 2px solid var(--primary-color);
    border-radius: 10px;

}

.button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0;
    border-radius: 8px;
    transition: 0.1s all ease;
    cursor: pointer;
}

.activeButton {
    background: var(--primary-color);
    cursor: unset;
}

.title {
    font-weight: 600;
    font-size: 17px;
    line-height: 153%;
    letter-spacing: 0.01em;
    transition: color 0.3s ease;
}