.container {
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    background: rgba(255,255,255,0.1);
    padding: 11px 12px 11px 16px;
    width: 100%;
    background: var(--bg-secondary-color);
}

.avatarContainer {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    position: relative;
    max-width: 40px;
    max-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-position: center;
    background-size: cover;
}

.avatar{
    overflow: hidden;
    background-position: center;
    background-size: cover;
   width: 100%;
   height: 100%;
}

.defaultAvatar{
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
}

.userContainer {
    flex: 1;
    display: flex;

    flex-direction: column;
    overflow: hidden;
    gap: 6px;
}

.iconContainer {
    border-radius: 8px;
    width: 31px;
    height: 31px;
    background: rgba(255, 255, 255, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;
}

.header {
    display: flex;
    gap: 8px;
}

.header__username {
    font-weight: 500;
    font-size: 14px;
    line-height: 129%;
    letter-spacing: -0.02em;
    color: #fff;
}

.reward {
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;
    letter-spacing: -0.02em;
    color: #FFE03B;
}
.reward span{
    color: #b4b4b4;
    font-size: 12px;
}

.spinnerContainer {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.1);
    border-left-color: #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  