/* Game Base Styles */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.h-screen {
  height: 100vh;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.overflow-hidden {
  overflow: hidden;
}

.touch-none {
  touch-action: none;
}

/* Positioning */
.top-4 {
  top: 1rem;
}

.left-4 {
  left: 1rem;
}

.right-4 {
  right: 1rem;
}

.bottom-4 {
  bottom: 1rem;
}

/* Text Styles */
.text-white {
  color: white;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.font-bold {
  font-weight: 700;
}

/* Background Colors */
.bg-chessboard {


  background-color: rgb(17, 24, 39, 0.2);
  background-image:
    linear-gradient(45deg, #1f2937 25%, transparent 25%),
    linear-gradient(135deg, #1f2937 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #1f2937 75%),
    linear-gradient(135deg, transparent 75%, #1f2937 75%);
  background-size: 50px 50px;
  background-position: 0 0, 0 0, 50px 50px, 50px 50px;
}

.bg-gray-600 {
  background-color: #4b5563;
}

.bg-gray-800 {
  background-color: #1f2937;
}

.bg-gray-900 {
  background-color: #111827;
}

.bg-black {
  background-color: #000000;
}

.bg-blue-500 {
  background-color: #3b82f6;
}

.bg-blue-600 {
  background-color: #2563eb;
}

.bg-blue-700 {
  background-color: #1d4ed8;
}

.bg-red-500 {
  background-color: #ef4444;
}

.bg-yellow-400 {
  background-color: #facc15;
}

/* Opacity */
.opacity-70 {
  opacity: 0.7;
}

.bg-opacity-70 {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Border */
.border {
  border-width: 1px;
}

.border-white {
  border-color: white;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

/* Spacing */
.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

/* Hover Effects */
.hover\:bg-blue-700:hover {
  background-color: #1d4ed8;
}

/* Reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  overflow: hidden;
}

/* Additional Game-specific styles */
.shooting-range-circle {
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 50%;
}

.player {
  transform-origin: center center;
}

.enemy {
  transition: transform 0.1s ease;
}

.bullet {
  box-shadow: 0 0 5px rgba(250, 204, 21, 0.6);
}

.particle {
  opacity: 1;
  transition: opacity 0.5s linear;
}

/* Button styles */
button {
  cursor: pointer;
  outline: none;
  border: none;
  transition: background-color 0.2s;
}

button:active {
  transform: translateY(1px);
}

/* Mobile optimization - prevent selection */
@media (max-width: 768px) {
  * {
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }
}