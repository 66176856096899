.loader {
    position: fixed;
    z-index: 10001;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

.hidden {
    opacity: 0;
    pointer-events: none;
}