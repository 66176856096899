.withdrawhs {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
    overflow-y: scroll;
    padding-bottom: 40px;
}

.withdrawhs::-webkit-scrollbar {
    display: none;
}



/* dddd */
.depositTab {
    margin-top: 20px;
    padding: 0;
    overflow-y: scroll;
    scrollbar-color: var(--primary-color) #000;
    scrollbar-width: thin;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
    padding-bottom: 60px;
}

.depositTab::-webkit-scrollbar {
    overflow-y: scroll;
    scrollbar-color: #007 #bada55;
}

.bannerTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 133%;
    color: rgba(255, 255, 255, 0.6);
    margin: 0 0 4px;
}

.bannerTitle span {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    /* background: var(--bg-secondary-color); */
    padding: 20px 16px;
    border-radius: 8px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.title {
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #fff;
}

.description {
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #b4b4b4;
}

.highlight {
    color: #FFE03B;
    background: #272727;
    border-radius: 4px;
    font-weight: 600;
    padding: 4px 3px;

    white-space: nowrap;
    flex-shrink: 0;
}

.buyBanner {
    background: var(--bg-secondary-color);
    padding: 20px 18px 20px;
    border-radius: 16px;
    /* padding-left: 40%; */
    position: relative;
    overflow: hidden;
    flex-shrink: 0;


    /* border: 1px solid #E7E0AE; */
    /* background: #e7e0ae21; */
}

.buyTitle {
    text-align: center;
    font-weight: 500;
    margin-bottom: 16px;
    font-size: 16px;
    /* color: #E7E0AE; */
}



.buyBanner .image {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 80%;
}

.buyBanner .buyButton {
    background: #E7E0AE;
    color: #000;
}

.stepButton {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
}

.deposit_main {
    /* flex: 1; */
    background: var(--bg-secondary-color);
    padding: 20px 16px 10px;
    border-radius: 16px;
}

.depositTitle {
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 18px
}

/* .depositInput {
    position: relative;
}

.depositAmountButtons {
    display: flex;
    flex-direction: row-reverse;
    gap: 6px;
    margin-bottom: 4px;
    position: absolute;
    top: 0;
    right: 12px;
    transform: translateY(-50%);
    background: var(--bg-secondary-color);
}

.depositAmountButtons div {
    background: rgba(255, 255, 255, 0.1);
    font-size: 14px;
    padding: 2px 4px;
    border-radius: 4px;
    cursor: pointer;
} */

.depositButtons {
    display: flex;
    gap: 12px;
    margin-top: 16px;

}

.checkButton {
    font-size: 12px;
    /* background: #fff; */
    color: #fff;
    font-weight: 400;
    line-height: 110%;
    text-align: center;
    width: fit-content;
    background: rgba(255, 255, 255, 0.1);
    padding-left: 20px;
    padding-right: 20px;
}



.infoAlert {
    display: flex;
    font-size: 10px;
    gap: 16px;
    font-weight: 400;
    color: #b4b4b4;
    /* margin-bottom: 12px; */
    margin-top: 8px;
    align-items: center;
}

.infoAlert__icon {
    width: 36px;
    flex-shrink: 0;
    opacity: 60%;
}





/* swap */




.buyButton {
    /* стили для кнопки "купить" */
    /* у вас уже есть PrimaryButton, так что можно использовать */
    width: 100%;
    /* max-width: 200px; */
    background: #E7E0AE;
    color: #000;
}