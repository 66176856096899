.container {
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 100px;



}

/* .container::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url('../../assets/923\ copy.jpg');
    background-position-x: center;
    background-position-y: -100px;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.3;
} */

.container::-webkit-scrollbar {
    display: none;
}

.balanceHeader {
    margin: 20px 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    text-align: center;
    color: #fff;
}

.balanceButtons {
    display: flex;
    padding: 0 30px;
    margin-top: 20px;
    justify-content: center;
    gap: 10px;
}





.payment {
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.paymentButtons {
    display: flex;
    gap: 10px;
}

.paymentButton_ton {
    background: rgba(0, 152, 234, 0.5);
    color: #fff;
    border-radius: 8px;
    padding: 8px 12px;

    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;

    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    flex: 1
}

.paymentButton_coin {
    background: rgb(53, 231, 229, .7);
    color: #fff;
    background: rgba(255, 255, 255, .8);
    color: black;
    border-radius: 8px;
    padding: 8px 12px;

    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;

    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.summaryInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;

}

.options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 6px 0 30px;
}

.option {
    display: flex;
    padding: 16px 12px;
    gap: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    transition: 0.1s all ease-in-out;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
}

.optionPrices {
    display: flex;
    align-items: center;
    gap: 6px;
}

.option_selected {
    /* border-color: rgba(53, 231, 229, 0.5); */
    /* background: rgba(53, 231, 229, 0.4); */
    box-shadow: 0 0 16px 0 #1c9d85;
    background: rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 1);
}

.option_iconContainer {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.15);
    /* background: rgba(0, 146, 192, 0.5); */
    border-radius: 8px;
    flex-shrink: 0;
    /* background: rgba(53, 231, 229, 0.); */
}

.option_description {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    margin-bottom: 10px;
    transition: 0.1s all ease-in-out;
}

.option_description__selected {
    color: rgba(255, 255, 255, 0.9);
}

.option_price__coin {
    display: flex;
    align-items: center;
    gap: 6px;
    background: rgba(255, 255, 255, 0.2);
    width: fit-content;
    padding: 4px 8px;
    border-radius: 4px;
}

.option_price__ton {
    display: flex;
    align-items: center;
    gap: 6px;
    background: rgba(0, 152, 234, 0.5);
    color: #fff;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 4px;
}


.modalSubheader {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    font-weight: 700;
}

.modalBalance {
    font-weight: 400;
    font-size: 18px;
    line-height: 89%;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    margin: 0 0 12px;
}


.userInfo {
    color: #fff;
    width: 100%;
    word-wrap: break-word;
    text-wrap: wrap;
}

.errorContainer {
    width: 100vw;
    height: 100vh;
    background: #000;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.error__title {
    margin: 0;
    text-transform: uppercase;
    font-size: 18px;
}

.error__description {
    font-size: 12px;
    line-height: 135%;
    margin-bottom: 24px;
    padding: 0 40px;
    text-align: center;
    opacity: 0.7;
}

.error__button{
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 12px !important;
    width: fit-content !important;
}

.error__icon {
    position: absolute;
    font-size: 100vw;
    font-weight: 700;
    transform: rotate(-90deg);
    opacity: 0.1;
    letter-spacing: -5vw;
    filter: blur(40px);
}