.container {
    width: 100%;
    overflow: hidden;
    background-color: #0095ec3a;
    padding: 2px 12px;

    /* position: absolute; */



    margin: 0;
    border-radius: 4px;
}

.marqueeWrapper {
    position: relative;
    white-space: nowrap;
    flex-grow: 1;
    overflow: hidden;
}

.marqueeContent {
    display: inline-flex;
}

.textItem {
    display: inline-block;
    padding: 0 8px;

    font-weight: 500;
    color: #00C749;
}



.container {
    background: radial-gradient(circle, rgba(31, 129, 67, 0.6), #281354);
    background-size: 400% 400%;
    animation: g21Anim 10s ease infinite;

}




@keyframes g21Anim {
    0% {
        background-position: 0% 0%;
    }

    50% {
        background-position: 100% 100%;
    }

    100% {
        background-position: 0% 0%;
    }
}