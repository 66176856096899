.slider {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sliderNavigation {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 40px;
    color: #999999;
}

.slider_arrow {
    height: 40px;
    width: 40px;
    background: rgb(153, 153, 153, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.slider_arrow svg {
    transform: translateX(-20%);
}

.coin_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;

}

.coin_title {
    font-size: 18px;
}

.table {
    background: rgba(255, 255, 255, 0.1);
    margin: 40px 20px 20px;
    padding: 0 0 8px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    /* gap: 8px; */

    overflow: hidden;
}

.tableHeader {
    background: rgba(255, 255, 255, 0.1);
}

.tableLine {
    display: flex;
    font-size: 12px;
    gap: 12px;
    border-bottom: 1px solid rgba(153, 153, 153, 0.1);
    padding: 6px 10px;
}

.tableLine:last-child {
    border: 0;
}

.table .tableLine .tableLine__title {
    flex: 2;
    /* padding: 6px 4px; */

}

.table .tableLine .tableLine__value {
    flex: 1;
    overflow: hidden;
    /* padding: 6px 4px; */
}

.button {
    font-size: 14px;
    font-weight: 400;
    width: fit-content;
    height: fit-content;

    padding: 14px 32px;
    border-radius: 64px;

}

.buttons_line {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    gap: 12px;
    align-items: center;
}

.infobutton {
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* border: 2px solid #0097ec; */
    background: rgb(0, 151, 236, 0.2);
    color: #0097ec;
    font-weight: 600;
}

.coin_desc {
    font-size: 12px;
    padding: 8px 20px;
    margin: 20px 0;
    text-align: center;
    color: #cdcdcd;
    line-height: 135%;
}

.link_desc {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: #0097ec;
    background: rgba(0, 151, 236, 0.1);
    width: fit-content;
    padding: 4px 12px;
    margin-top: 8px;
    margin: 8px auto 0;
}