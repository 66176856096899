.group {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.title {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-secondary-color);
    margin-bottom: 4px;
}

.children {
    display: flex;
    flex-direction: column;
    gap: 2px;
    
}