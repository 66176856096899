@keyframes slideUp {
  from {
    transform: translateY(100%);
    left: 0;
  }

  to {
    transform: translateY(0);
    left: 0;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
    left: 0;
  }

  to {
    transform: translateY(100%);
    left: 0;
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  /* backdrop-filter: blur(3px); */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: background 0.5s;
  z-index: 1000;
}

.modalOverlay:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.modalContent {
  border-radius: 16px 16px 0 0;
  backdrop-filter: blur(30px);
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.25);
  background: rgba(11, 11, 11, 0.95);

  overflow-x: hidden;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  border-radius: 16px 16px 0 0;
  padding: 19px 16px 20px;
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  max-width: 100%;
  width: 100vw;
  color: #fff;
  font-family: var(--font-family);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  /* background: url('../../assets/background.jpg'); */
  /* box-shadow: 0 -2px 28px 0 #2f806f; */
}

.modalContent::-webkit-scrollbar {
  display: none;
}

.slideUp {
  animation: slideUp 0.5s ease-out forwards;
}

.slideDown {
  animation: slideDown 0.5s ease-out forwards;
}

.closing {
  background: rgba(0, 0, 0, 0);
}

.modalHeader {
  display: flex;
  justify-content: center;
  margin-bottom: 34px;
  position: relative;
}

.modalTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  letter-spacing: -0.02em;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.modalCloseButton {
  height: 28px;
  width: 28px;
  line-height: 24px;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: rgba(255, 255, 255, 0.8);
  font-size: 22px;

  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  border: none;
  flex-shrink: 0;
  cursor: pointer;
  min-height: 28px;
  max-width: 28px;
}