/* BlackjackGame.module.css */

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* background-color: #1a202c; */
    max-height: 100vh;
    overflow-y: scroll;
    padding-bottom: 200px;
    padding-top: 200px;
}

.gameContainer {
    width: 320px;
    height: 600px;
    background-color: #1b4d3e;
    border-radius: 0.75rem;
    overflow: hidden;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    position: relative;
}

.header {
    background-color: #1a392e;
    padding: 0.75rem;
    color: white;
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid #17332a;
}

.gameField {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.75rem;
    position: relative;
}

.dealerArea,
.playerArea {
    margin-bottom: 1rem;
}

.scoreLabel {
    color: white;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
}

.cardsContainer {
    display: flex;
    gap: 0.5rem;
    min-height: 96px;
}

.card {
    position: relative;
    width: 64px;
    height: 96px;
    border-radius: 0.5rem;
    border: 2px solid #d1d5db;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    transition: all 0.3s;
}

.cardValue {
    font-size: 1.25rem;
    font-weight: bold;
}

.redCard {
    color: #e53e3e;
}

.blackCard {
    color: #2d3748;
}

.suitTop {
    position: absolute;
    top: 4px;
    left: 4px;
    font-size: 0.875rem;
}

.suitBottom {
    position: absolute;
    bottom: 4px;
    right: 4px;
    font-size: 0.875rem;
}

.cardBack {
    width: 100%;
    height: 100%;
    background-color: #2b6cb0;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardBackSymbols {
    color: white;
    font-size: 0.75rem;
}

.hiddenCard {
    width: 64px;
    height: 96px;
    border-radius: 0.5rem;
    border: 2px solid #d1d5db;
    background-color: #2b6cb0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hiddenCardSymbols {
    color: white;
    font-size: 0.75rem;
}

.otherPlayersTable {
    margin-top: 1rem;
    background-color: rgba(22, 101, 52, 0.3);
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 100%;
}

.otherPlayersTitle {
    color: white;
    text-align: center;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
}

.playersList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.playerRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(22, 78, 42, 0.3);
    border-radius: 0.25rem;
    padding: 0.5rem;
}

.playerName {
    color: white;
    font-size: 0.875rem;
}

.playerCards {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.playerCardIcon {
    width: 16px;
    height: 24px;
    background-color: #2b6cb0;
    border-radius: 0.125rem;
}

.playerStatus {
    color: white;
    font-size: 0.75rem;
    background-color: rgba(26, 32, 44, 0.6);
    border-radius: 0.25rem;
    padding: 0 0.25rem;
}

.controls {
    background-color: #1a392e;
    padding: 0.75rem;
    display: flex;
    justify-content: space-between;
}

.startButton {
    background-color: #d97706;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    width: 100%;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.startButton:hover {
    background-color: #b45309;
}

.hitButton {
    background-color: #2563eb;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.hitButton:hover {
    background-color: #1d4ed8;
}

.standButton {
    background-color: #dc2626;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.standButton:hover {
    background-color: #b91c1c;
}

.disabledButton {
    opacity: 0.5;
    cursor: not-allowed;
}

.animatedCard {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.5s;
    animation: cardDeal 0.5s;
}

.resultOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    text-align: center;
    z-index: 10;
    min-width: 200px;
}

.resultMessage {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.newGameButton {
    background-color: #d97706;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.newGameButton:hover {
    background-color: #b45309;
}

@keyframes cardDeal {
    0% {
        transform: translate(-50%, -50%);
        opacity: 1;
    }

    100% {
        transform: translate(100px, 100px);
        opacity: 0;
    }
}