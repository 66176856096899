/* PoolIcon.module.css */
.pool__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  background: #000;

  position: relative;
  border: 2px solid;
  flex-shrink: 0 !important;
}

.bronze {
  border-color: #8A5100;
  box-shadow: 0 0 5px 2px rgba(138, 81, 0, 0.45);
  width: 25vw !important;
  height: 25vw !important;
}

.silver {
  border-color: #7F7F7F;
  box-shadow: 0 0 5px 2px rgba(127, 127, 127, 0.25);
  width: 25vw !important;
  height: 25vw !important;
}

.golden {
  border-color: #FFB700;
  box-shadow: 0 0 5px 2px rgba(255, 183, 0, 0.25);
  width: 30vw !important;
  height: 30vw !important;
}

.golden.fixed, .silver.fixed, .bronze.fixed{
  width: 30vw !important;
  height: 30vw !important;
}

.golden::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 20px;
  height: 19px;
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%229%22%20viewBox%3D%220%200%2010%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M5.33394%200.194349C5.26548%200.0741651%205.13805%200%204.99999%200C4.86199%200%204.73456%200.0741651%204.66605%200.194349L2.78083%203.50294L0.52747%202.59901C0.393362%202.54522%200.240511%202.57131%200.131701%202.66656C0.0228909%202.76181%20-0.0235347%202.91017%200.011496%203.05069L1.62279%209H8.37722L9.9885%203.05069C10.0235%202.91017%209.97712%202.76181%209.8683%202.66656C9.75948%202.57131%209.60666%202.54522%209.47256%202.59901L7.21917%203.50294L5.33394%200.194349Z%22%20fill%3D%22%23FFB700%22/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  /* Дополнительные стили по необходимости */
}

.image {
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.defaultAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0e1112;
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  color: #fff;
  text-transform: uppercase;
  border-radius: 50%;
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  /* background: rgba(27, 52, 72, 0.8); */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-left-color: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

.level {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
  width: 32px !important;
  height: 32px !important;
  background: #fff;
  color: #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 95%;
}
.level_container{
  display: flex;
  align-items: flex-end;
  gap: 1px;
}

.level span{
  font-size: 0.5rem;
  line-height: 100%;
}

.level.golden::after {
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.level.bronze {
  background: #8A5100;
}

.level.silver {
  background: #7F7F7F;
}

.level.golden {
  background: #FFB700;
}