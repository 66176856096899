.main {
    height: 100%;
    width: 100%;
}

.header {
    display: flex;
    justify-content: center;
    position: relative;
}

.header img {
    max-height: 200px;
}

.buttons {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 90vw;
    top: 50%;
    transform: translateY(-50%);
}

.buttons button {
    width: 40px;
    height: 40px;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: #b4b4b4;
    display: flex;
    justify-content: center;
    align-items: center;

}

.buttons button svg {
    width: 24px;
    height: 24px;
}

.buttons button:first-child {
    transform: rotate(180deg);
}

.containerCLS {
    border-top: 1px solid #ff9800;
    background: linear-gradient(180deg, #ff98001c, transparent 50%) !important;
}

.titleCLS {
    margin-bottom: 0;
}

.pr_button {
    background: #ff9800;
font-size: 14px;
padding: 10px 12px
}

.pr_button_container {
    margin: 40px 0 0 0;
    padding: 0 20px;

}

.description {
    text-align: center;
    font-size: 14px;
    color: #b4b4b4;
    margin-top: 12px;
}