/* .container {
    width: 100%;
    overflow: hidden;
    background-color: #0095ec3a;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: absolute;
    top: 0;
    height: 14px;
}

.marqueeWrapper {
    position: relative;
    white-space: nowrap;
    flex-grow: 1;
    overflow: hidden;
}

.marqueeContent {
    display: inline-flex;
}

.textItem {
    display: inline-block;
    padding: 0 8px;
    font-size: 12px;
    font-weight: 500;
    color: #0097EC;
}

.buyButton {
    margin-left: 16px;
    padding: 4px 8px !important;
    background-color: #0097EC;
    color: #ffffff;

    background: #ffffff;
    color: #0097EC;

    font-weight: bold;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    flex-shrink: 0;
    transition: background-color 0.3s;

    width: fit-content;
    font-size: 12px;
}


.container {
    background: radial-gradient(circle, #6a11cb, #2575fc);
    background-size: 400% 400%;
    animation: g21Anim 10s ease infinite;
}

.textItem {
    color: #fff;
} */



.container {
    width: 100%;
    overflow: hidden;
    background-color: #0095ec3a;
    padding: 2px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: absolute;
    top: 0;
    height: 24px;
}

.marqueeWrapper {
    position: relative;
    white-space: nowrap;
    flex-grow: 1;
    overflow: hidden;
}

.marqueeContent {
    display: inline-flex;
}

.textItem {
    display: inline-block;
    padding: 0 8px;
    font-size: 12px;
    font-weight: 500;
    color: #0097EC;
}

.buyButton {
    margin-left: 16px;
    padding: 4px 8px !important;
    background-color: #0097EC;
    color: #ffffff;

    background: #ffffff;
    color: #0097EC;

    font-weight: bold;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    flex-shrink: 0;
    transition: background-color 0.3s;

    width: fit-content;
    font-size: 10px;
}


.container {
    background: radial-gradient(circle, #6a11cb, #2575fc);
    background-size: 400% 400%;
    animation: g21Anim 10s ease infinite;

}

.textItem {
    color: #fff;
}



@keyframes g21Anim {
    0% {
        background-position: 0% 0%;
    }

    50% {
        background-position: 100% 100%;
    }

    100% {
        background-position: 0% 0%;
    }
}