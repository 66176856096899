.block {
    display: flex;
    gap: 20px;
}

.block .name {
    color: #0097EC;
    font-size: 14px;
    background: rgb(0, 151, 236, 0.1);
    padding: 4px 6px;
    border-radius: 4px;
    cursor: pointer;
}

.block .boosts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}
