.content {
    height: calc(100% - 80px);
    position: relative;
    overflow-y: scroll;
}


.header {
    display: flex;
    justify-content: center;
}

.header img {
    max-height: 200px;
}

.button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px 56px 20px;
}

.title {
    margin: 16px 0 20px;
    font-size: 16px;
    text-align: center;
}

.title span {
    background: #0097ec33;
    padding: 4px 6px;
    color: #0097ec;
    border-radius: 6px;
    margin: 0 4px;
}


.headerCLS {
    margin-bottom: 0;
}

.inputCLS {
    /* margin: 40px 0 0 0; */
    width: 100%;
}

.modalCLS {
    /* background: linear-gradient(180deg, #0097ec17, #0000 50%), var(--bg-secondary-color) !important; */
    border-top: 1px solid #0097ec;
    padding-bottom: 120px;
}

.titleCLS {

    font-weight: 600;
}

.checkLine {
    font-size: 14px;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
    color: #b4b4b4;
    margin-left: 10px;
    cursor: pointer;
}

.checkBox {
    height: 24px;
    width: 24px;
    border: 1px solid #b4b4b4;
    border-radius: 4px;
    position: relative;
    transition: 0.3s all ease-in-out;
}

.active {
    background: var(--primary-color);
    border: none;

}

.active::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1em;
    height: 1em;
    transform: translate(-50%, -50%);
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'><path d='M5 13l4 4L19 7'/></svg>");

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.inventory {
    height: 59px;
    width: 100%;
    border: 1px solid#ffffff8a;
    color: #b4b4b4;
    background: #ffffff1a;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 8px;
}

.primaryButton {
    font-size: 14px;
}

.switchContainer {
    position: relative;
    width: 100%;
    height: 59px;
    margin-top: 40px;

}

.inputWrapper,
.inventoryWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 59px;

    transform: translateX(0);
    transition: opacity 0.3s ease;
    display: none;
    align-items: center;
}

.visible {
    display: flex;
}

/* Дополнительно можно добавить анимацию сдвига:
     Например, при переходе к inventory сдвигать input влево, а inventory появляться справа.
     Тогда можно добавить что-то вроде:
   */
/* .inputWrapper:not(.visible) {
    transform: translateX(-20px);
}

.inventoryWrapper:not(.visible) {
    transform: translateX(20px);
} */

.additionalDesc {
    /* text-align: center; */
    font-size: 12px;
    /* padding: 0 20px; */
    line-height: 135%;
    color: var(--text-secondary-color);
    /* display: none; */
    padding: 0 10px;
    margin-top: 20px;
}