.buttonsContainer {
    flex: 1;
    display: flex;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 16px 16px 26px;
    background: rgba(0, 0, 0, 1);
    gap: 10px;
}

/* .buttonsContainer .primaryButton{
    : 0 16px;
} */

.backLink {
    text-decoration: none;
    display: flex;
}

.primaryButton {
    padding: 16px;
    font-size: 14px;
    line-height: 100%;
}