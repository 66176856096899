.container {
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    /* padding-bottom: 200px; */

}


.container::-webkit-scrollbar {
    display: none;
}

.balanceHeader {
    margin: 20px 0;
    font-weight: 700;
    font-size: 20px;
    line-height: 80%;
    text-align: center;
    color: #fff;
}



.balanceButtons {
    display: flex;
    padding: 0 30px;
    margin-top: 20px;

    justify-content: center;
    gap: 30px;
}

.miningStatus {
    font-weight: 700;
    font-size: 17px;
    line-height: 94%;
    text-align: center;
    color: #f1f1f1;
}

.miningStatus_status {
    color: #55C000;
}

.infoSection {
    display: flex;
    flex-direction: column;
    padding: 32px 26px;

    padding-bottom: 200px;
    margin-top: 30px;
    border-radius: 28px;

    background-repeat: no-repeat;
    background-size: cover;
}

.info_buttons {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 27px;
}

.storeButton {
    font-weight: 700;
    font-size: 19px;
    line-height: 84%;
    color: #fff;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);
    padding: 13px 16px;
}

.filterButton {
    font-weight: 400;
    font-size: 19px;
    line-height: 84%;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 6px;
}

.statsTable {
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
    border-collapse: collapse;
    color: #fff;
    margin-bottom: 16px;
}

.statsTable__row {}

.statsTable__cell {
    border: none;
    padding: 2px;
}

.statsTable__total {
    padding-top: 10px;
}

.payment {
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.paymentButtons {
    display: flex;
    gap: 10px;
}


.paymentButton_ton {
    background: var(--primary-color);
    color: #fff;
    border-radius: 8px;
    padding: 8px 12px;

    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;

    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    flex: 1
}

.paymentButton_coin {
    background: #fff;
    background: var(--primary-color);
    color: #fff;
    /* background: rgba(255, 255, 255, .8); */
    /* color: #1c1c1c; */
    border-radius: 8px;
    padding: 8px 12px;

    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.01em;

    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;

    position: relative;
    height: 50px;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
}

.paymentButton_coin .coin {
    position: absolute;
    left: 0;
    transform: translateX(-50%);
    /* height: 60px;
    width: 60px; */
    height: 60px;
    width: 60px;
    animation: rotate 5s infinite;
}

@keyframes rotate {
    0% {
        transform: translateX(0) rotate(0deg);
    }

    50% {
        transform: translateY(-25%) translateX(0) rotate(10deg);
    }

    100% {
        transform: translateX(0) rotate(0deg);
    }
}

.paymentButton_disabled {
    /* background: rgb(53, 231, 229, .7); */
    /* color: #E1AF14; */
    /* color: #FFFFFF; */
    /* background: rgba(255, 255, 255, .8); */
    /* border: 1px solid var(--primary-color); */
    /* background: rgb(255, 191, 0, 0.2); */
    color: var(--primary-color);
    border-radius: 8px;
    padding: 14px 12px;

    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.01em;

    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.summaryInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;

}

.options {
    /* display: flex; */
    /* flex-direction: column; */

    position: relative;
    margin: 6px 0 30px;
}

.sliderArrowLeft {
    position: fixed;
    background: transparent;
    border: 0;
    left: 32px;
    top: 30%;
    /* transform: translateY(-50%); */
    z-index: 2;
    cursor: pointer;
}

.sliderArrowRight {
    position: fixed;
    background: transparent;
    border: 0;
    position: fixed;
    right: 32px;
    top: 30%;
    transform: rotate(180deg);
    z-index: 2;
    cursor: pointer;
}

.option {
    display: flex;
    padding: 16px 12px;
    gap: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    transition: 0.1s all ease-in-out;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
}

.optionPrices {
    display: flex;
    align-items: center;
    gap: 6px;
}

.option_selected {
    /* border-color: rgba(53, 231, 229, 0.5); */
    /* background: rgba(53, 231, 229, 0.4); */
    box-shadow: 0 0 16px 0 #1c9d85;
    background: rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 1);
}

.option_iconContainer {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.15);
    /* background: rgba(0, 146, 192, 0.5); */
    border-radius: 8px;
    flex-shrink: 0;
    /* background: rgba(53, 231, 229, 0.); */
}

.option_description {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    margin-bottom: 10px;
    transition: 0.1s all ease-in-out;
}

.option_description__selected {
    color: rgba(255, 255, 255, 0.9);
}

.option_price__coin {
    display: flex;
    align-items: center;
    gap: 6px;
    background: rgba(255, 255, 255, 0.2);
    width: fit-content;
    padding: 4px 8px;
    border-radius: 4px;
}

.option_price__ton {
    display: flex;
    align-items: center;
    gap: 6px;
    background: rgba(0, 152, 234, 0.5);
    color: #fff;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 4px;
}


.modalSubheader {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    font-weight: 700;
}