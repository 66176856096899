.button {

  background: var(--primary-color);
  border-radius: 8px;
  padding: 15px 12px;
  width: 100%;

  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #fff;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  text-decoration: none;
}

.button div{
  text-decoration: none;
}



.spinner {
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-top: 2px solid white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
  position: absolute;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.disabled{
  opacity: 0.4;
}

