.container {
    position: relative;
    width: 100%;
    height: 60px; /* Соответствует h-[180px] */
    /* background-color: #1f2937;  */
    background-color: #121212; 
    /* border: 2px solid #374151;  */
    overflow: hidden;
    border-radius: 6px;
  }
  
  .innerContainer {
    position: absolute;
    display: flex;
    align-items: center; /* Соответствует items-center */
    height: 100%;
  }
  
  .motionContainer {
    display: flex;
    align-items: center; /* Соответствует items-center */
    height: 100%;
  }
  
  .centralIndicator {
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px; /* Tailwind w-0.5 соответствует 0.125rem, обычно 2px */
    background-color: #ef4444; /* Tailwind bg-red-500 */
    transform: translateX(-50%);
    z-index: 10;
  }