.modalBalance {
    font-weight: 400;
    font-size: 14px;

    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    margin: 0 0 12px;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.modalBalance_balances {
    display: flex;
    gap: 4px;
}

.modalBalance_minex {
    font-size: 14px;
    padding: 6px 8px;
    display: flex;
    gap: 4px;
    align-items: center;
    /* border: 1px solid #ffe137; */
    border-radius: 6px;
    background: rgba(90, 83, 83, 0.1);
    color: #9e9e9e;
}

.modalBalance_ton {
    font-size: 14px;
    padding: 6px 8px;
    display: flex;
    gap: 4px;
    align-items: center;
    /* border: 1px solid #0098EA; */
    border-radius: 6px;
    background: rgb(0, 152, 234, 0.1);
    color: #0098EA;
}

.modalBalance_minex_min {
    font-size: 12px;
    padding: 0 8px;
    display: flex;
    gap: 4px;
    align-items: center;
    /* border: 1px solid #0098EA; */
    border-radius: 6px;
    /* background: rgba(90, 83, 83, 0.1); */
    color: #9e9e9e;
}

.modalBalance_ton_min {
    font-size: 12px;
    padding: 0 8px;
    display: flex;
    gap: 4px;
    align-items: center;
    /* border: 1px solid #0098EA; */
    border-radius: 6px;
    /* background: rgb(0, 152, 234, 0.1); */
    color: #0098EA;
}