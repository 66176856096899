.history {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
    flex: 1;
    overflow: scroll;
    position: relative;
    padding-bottom: 24px;

}

.historyContainer {
    display: flex;
    position: relative;



    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow: hidden;
    position: relative;
}

.historyContainer::before {
    content: '';
    width: 100%;
    height: 30px;
    background: linear-gradient(360deg, rgba(11, 11, 11, 1) 20%, rgba(11, 11, 11, 0.8) 50%, transparent 100%);
    ;
    position: absolute;
    bottom: 0;
    z-index: 1;

}

.historyContainer::-webkit-scrollbar,
.history::-webkit-scrollbar {
    display: none;
}

.history .item {
    display: flex;

    border-radius: 8px;
    padding: 12px 8px;
    gap: 12px;
    position: relative;
    border: 0;
    border-left: 1px;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-style: solid;
    background: #101010;

}



.pink {
    border-color: rgba(197, 0, 98, 0.9) !important;
}

.gray {
    border-color: rgba(77, 77, 77, 0.8) !important;
}

.yellow {
    border-color: rgba(255, 162, 0, 0.8) !important;
}

.blue {
    border-color: rgba(54, 58, 255, 0.8) !important;
}

.purple {
    border-color: rgba(131, 54, 255, 0.8) !important;
}

.red {
    border-color: rgba(255, 43, 31, 0.7) !important;
}

.history .item .title {
    font-size: 13px;
}

.history .item .title span {
    font-size: 12px;
    color: #b4b4b4;
}


.history .item .lbx {
    font-size: 13px;
}

.history .item .lbx span {
    font-size: 12px;
    color: #b4b4b4;
}

.history .item .date {
    font-size: 12px;
    color: #b4b4b4;
}

.history .item .date span {
    font-size: 12px;
    color: #b4b4b4;
}



.history .item .buttons {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 12px;
    bottom: 8px;
    align-items: flex-end;
    gap: 8px;
}

.history .item .buttons .button {
    font-size: 9px;
    width: fit-content;
    padding: 7px 13px;
    border-radius: 4px;
}

.history .item .buttons .claimed {
    font-size: 12px;
    width: fit-content;
    padding: 8px 16px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    color: #b4b4b4;
}

.left {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 70px;
}

.right {
    flex: 1;
    display: flex;
    gap: 2px;
    flex-direction: column;


    padding: 0 0 20px;
}

.premiumIconSVG {
    color: #0097ec;
}


.card {
    background: #1c1c1e;
    padding: 16px 12px;
    border-radius: 4px;

    border-radius: 8px;
}

.icon__holder {}

.card_content {}

.card_titleLine {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.card_buttons {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.card_button {
    font-size: 12px;
    padding: 8px 20px;
    border-radius: 4px;
    width: fit-content;
    height: fit-content;
}

.card_button.claimed {
    background: rgba(255, 255, 255, 0.1);
}

.card_button_winners {
    font-size: 10px;
    padding: 8px 6px;
    /* margin-bottom: 6px; */
    border-radius: 4px;
    color: #0097ec;
    background: rgb(0, 151, 236, 0.1);
}

.card_title {
    display: flex;
    gap: 6px;
}

.card_dates {
    display: flex;
    color: #b4b4b4;
    font-size: 10px;
    margin-top: 4px;
}

.card_winners__holder {}

.card_winners__title {
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.card_winners {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.card_winner {
    display: flex;
    gap: 4px;
    font-size: 12px;
}


.card_status {
    font-size: 12px;
    margin-top: 8px;

    color: #ff3636;
    font-weight: 500;
    padding: 4px 8px;
    background: rgb(255, 54, 54, 0.1);

}

.card_status.claimable {
    color: #49ff36;
    background: rgb(73, 255, 54, 0.1);
}

.card_user {
    display: flex;
    gap: 4px;
    align-items: center;
    /* background: rgba(255, 255, 255, 0.1);
    padding: 4px 6px; */
}

.card_avatar {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #b4b4b4;
}


.winners {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.winner {
    display: flex;
    background: #1E1E1E;
    padding: 6px 12px;
    border-radius: 8px;

    gap: 10px;
    justify-content: space-between;
    min-height: 72px;
}

.winner_user {
    display: flex;
    align-items: center;
    font-size: 11px;
    gap: 12px;
}

.winner_item {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    gap: 4px;
    /* gap: 12px; */
}

.winner_user__avatar {
    height: 32px;
    width: 32px;
    background: #101010;
    border-radius: 50%;
}

.premiumText {
    display: flex;
    gap: 6px;
    font-size: 12px;
    font-weight: 500;
    line-height: 135%;
}

.premiumIconBg {
    color: #0097ec;
}