.depositInput {
    position: relative;
}

.depositAmountButtons {
    display: flex;
    flex-direction: row-reverse;
    gap: 6px;
    margin-bottom: 4px;
    position: absolute;
    top: 0;
    right: 12px;
    transform: translateY(-50%);
    /* background: var(--bg-secondary-color); */
    z-index: 1;
}

.depositAmountButtons div {
    background: rgb(41, 41, 41);
    font-size: 14px;
    padding: 2px 4px;
    border-radius: 4px;
    cursor: pointer;
}