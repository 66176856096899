.loader {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  /* Плавное исчезновение */
}

.hidden {
  opacity: 0;
  pointer-events: none;
  /* Чтобы лоадер не блокировал клики после исчезновения */
}

.loader_icon {
  content: "";
  width: 25px;
  height: 25px;
  border: 5px solid #fff;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  transform: translateY(-50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.mainContainer {
  position: relative;
}

.container {
  /* padding-top: 22px; */
  position: relative;
}

.backGradient {
  border-radius: 35px;
  width: 30vh;
  height: 10vh;
  /* filter: blur(50px); */
  margin: 42px auto 0 auto;
  position: absolute;
  top: 30px;
  z-index: -1;
  pointer-events: none;
  will-change: transform, opacity;
}

.header {
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  text-align: center;
  color: #fff;
  margin-top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;


}

.limits {
  font-size: 9px;
  background: rgba(255, 255, 255, .1);
  /* background: rgb(0, 151, 236, .1); */

  padding: 1px 8px;
  display: flex;
  gap: 3px;
  border-radius: 16px;
  color: rgba(255, 255, 255, .8);
  /* color: rgb(0, 151, 236, .6); */

  display: flex;
  gap: 4px;



}

.limits {
  background: radial-gradient(circle, #6a11cb, #2575fc);
  background-size: 400% 400%;
  animation: g21Anim 10s ease infinite;
}

@keyframes g21Anim {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.limits_left {
  color: #fff;
  /* color: #0097ec; */
}

.limits_separator {}

.limits_total {
  font-size: 8px;
}

.button {
  cursor: pointer;
  font-size: 24px;
  color: white;
  background: transparent;
  border: none;
  font-family: slick;
}

.cardInfo {
  display: flex;
  padding: 0 40px;
  justify-content: space-between;
  gap: 10px;
}

.cardInfo_item {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  flex: 1;
  padding: 20px 12px 12px;
  border-radius: 12px;
}

.item_title {
  font-weight: 300;
  font-size: 12px;
  line-height: 100%;
  color: rgba(255, 255, 255, 0.6);
}


.item_text {
  font-weight: 400;
  font-size: 26px;
  line-height: 100%;
  color: #fff;
}

.slider_image {
  position: relative;
  padding: 0 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.slider__navigation {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  width: 100%;
  z-index: 2;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.sliderImage {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide-enter-right {
  animation: slideInRight 0.5s forwards;
  z-index: -1;
}

.slide-enter-left {
  animation: slideInLeft 0.5s forwards;
  z-index: -1;
}

.videoCard {
  width: 80%;
  object-fit: cover;
  object-position: center;
  min-width: 60vw;
  height: 30vh;
  width: 60%;
  max-height: 30vh;
  max-width: 60%;

}

.buyandwin {
  position: absolute;
  bottom: 10px;

  padding: 8px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 6px;

  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  text-transform: uppercase;
  text-align: center;
  color: #fff;

  background: linear-gradient(180deg, #f00 0%, #900 100%);
}

.buyandwin img {
  height: 18px;
}



.rune_additional {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(227, 218, 78, .15);
  color: #e3da4e;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  width: 100%;

  /* top: 0; */
  text-align: center;
  font-weight: 500;
}