.main {
    padding: 24px 16px 100px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    max-height: calc(100vh - 89.4px);
}




.main::-webkit-scrollbar {
    display: none;
}

.minexDataIcon {
    color: var(--primary-color);
}

.titleBar {
    display: flex;
    align-items: center;
    gap: 4px;
}

.titleBar .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #fff;
}

.prices {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin: 28px 0;
    position: relative;
}

.prices .chart {
    position: absolute;
    width: 50%;
    right: 0;
    bottom: 0;
}

.prices .priceLine {
    display: flex;
    align-items: center;
    gap: 4px;
}

.prices .priceLine:first-of-type .icon {
    height: 18px;
    width: 18px;
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}


.prices .priceLine .icon {
    height: 18px;
    width: 18px;
    color: #7EFF39;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prices .priceLine .price {
    font-weight: 500;
    font-size: 15px;
    line-height: 120%;
    color: #c4bbbb;
}

.progressesContainer {
    display: flex;
    flex-direction: column;
    gap: 11px;
    margin-bottom: 35px;
}

.progressesContainer .progressGroup .title {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    color: #c4bbbb;
    margin-bottom: 6px;
}

.progressesContainer .progressGroup .progressbar {
    border-radius: 3px;
    height: 20px;
    background: var(--bg-secondary-color);
}

.progressesContainer .progressGroup .progressbar .progress {
    height: 100%;
    background: #fff;
    border-radius: 3px;
}

.minexData {
    display: flex;
    gap: 7px;
    margin-bottom: 16px;
}

.minexData .leftColumn,
.minexData .rightColumn {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.minexData .leftColumn .minersData,
.minexData .leftColumn .poolsData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-secondary-color);
    border-radius: 8px;
    padding: 12px 16px;
    color: #fff;
    flex: 1;
}

.minexData .rightColumn .gpuData,
.minexData .rightColumn .thData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 8px;
    padding: 12px 16px;
    color: #fff;
}

.minexData .rightColumn,
.minexData .leftColumn {
    flex: 1;
}

.minexData .rightColumn {
    background-color: var(--bg-secondary-color);
    border-radius: 8px;
}

.minexData .leftColumn .minersData div,
.minexData .leftColumn .poolsData div,
.minexData .rightColumn .gpuData div,
.minexData .rightColumn .thData div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.minexData .leftColumn .minersData .title .icon svg,
.minexData .leftColumn .poolsData .title .icon svg,
.minexData .rightColumn .gpuData .title .icon svg,
.minexData .rightColumn .thData .title .icon svg {
    height: 16px;
    color: var(--text-secondary-color);
    /* color: var(--primary-color); */
}

.minexData .leftColumn .minersData .title .text,
.minexData .leftColumn .poolsData .title .text,
.minexData .rightColumn .gpuData .title .text,
.minexData .rightColumn .thData .title .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: var(--text-secondary-color);

    /* color: var(--primary-color); */
    font-weight: 600;

}

.minexData .leftColumn .minersData .title,
.minexData .leftColumn .poolsData .title,
.minexData .rightColumn .gpuData .title,
.minexData .rightColumn .thData .title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 2px;
}

.minexData .leftColumn .minersData .value,
.minexData .leftColumn .poolsData .value,
.minexData .rightColumn .gpuData .value,
.minexData .rightColumn .thData .value {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    color: #c4bbbb;
    margin: 12px 0;
}

.minexData .rightColumn .separator {
    height: 1px;
    background-color: #fff;
    margin: 0 16px;
    opacity: 0.04;
}

.minexData .icon {
    margin-right: 8px;
}

.linksHeader {
    margin-bottom: 9px;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #c4bbbb;
}

.linksContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 40px;
}




.buttonsContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 24px 16px;
    background: rgba(0, 0, 0, 0.70);
}

/* .buttonsContainer .primaryButton{
    : 0 16px;
} */

.backLink {
    text-decoration: none;
}

.headerIcon{
    width: 28px;
    height: 28px;
    border-radius: 4px;
    background: rgba(0, 151, 236, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}