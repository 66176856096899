.container {
    min-height: 100vh;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    position: relative;
    /* z-index: 0; */
    /* padding-bottom: 200px; */
    display: flex;
    flex-direction: column;

    padding: 12px 8px 200px;
    gap: 12px;


    /* background: #111;

    background-size: 60px 60px;
    -webkit-animation: bscale 10s infinite linear;
    animation: bscale 20s infinite linear; */

    /* background-image: url("data:image/svg+xml,%3Csvg width='39' height='39' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1785_4257)'%3E%3Crect width='39' height='39' fill='white'/%3E%3Cpath d='M-0.000960425 0.000960414L9.74354 0.000371075L-14.6546 24.3759L-19.5402 19.4904L-0.000960425 0.000960414Z' fill='%230097EC'/%3E%3Crect x='24.835' y='-15.1152' width='6.90922' height='55.8472' transform='rotate(45 24.835 -15.1152)' fill='%23007EC5'/%3E%3Crect x='29.7236' y='-10.2266' width='6.90922' height='55.8472' transform='rotate(45 29.7236 -10.2266)' fill='%230097EC'/%3E%3Cpath d='M29.3 -0.000488303L38.9995 -0.000488288L0.00355687 39.0332L-4.882 34.1476L29.3 -0.000488303Z' fill='%23007EC5'/%3E%3Cpath d='M39 0L39 10L10 39L0.0320622 39.0002L39 0Z' fill='%230097EC'/%3E%3Cpath d='M39.0002 9.80196L39.0001 19.4999L20.0001 38.9999L9.80062 38.9992L39.0002 9.80196Z' fill='%23007EC5'/%3E%3Cpath d='M39 19.5L39.0005 29.5L29.4995 38.9995L19.5703 39.001L39 19.5Z' fill='%230097EC'/%3E%3Cpath d='M38.9997 29.3191L38.9997 39.0002H29.2996L38.9997 29.3191Z' fill='%23007EC5'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1785_4257'%3E%3Crect width='39' height='39' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A"); */

}

.container::-webkit-scrollbar {
    display: none;
}

.title {
    font-size: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
}

.subtitle {
    display: flex;
    gap: 6px;
    align-items: center;

    margin-bottom: 8px;
}

.subtitle .icon_container {
    height: 22px;
    width: 22px;
    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.subtitle .text {}


.subdescription {
    font-size: 12px;
}


.giveUsersCard {
    background: #111;
    padding: 12px 16px;
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius: 8px;
}

.lootboxes {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.lbx_navigation {
    display: flex;
    gap: 8px;
    justify-content: center;
}

.lbx_navigation__button {
    background: #111;
    width: 12px;
    height: 12px;
    border-radius: 20px;

}

.lbx_navigation__button.active {
    background: #0097ec;
}

.lbxTitleLine {
    display: flex;
    justify-content: space-between;
}

.historyButton {
    color: #0097ec;
    background: rgba(0, 151, 236, 0.2);
    padding: 6px 10px;
    border-radius: 4px;

    display: flex;
    gap: 6px;
    align-items: center;

    font-size: 12px;
}

.gradientBlue {}