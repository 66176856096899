.container {
    /* flex: 1; */
    background: rgba(255, 255, 255, 0.05);
    /* background: transparent; */
    padding: 8px 16px;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 4px;
    flex-shrink: 0;
    cursor: pointer;
    transition: 0.3s color ease-in-out;
}

.container {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.container a {
    position: relative;
    transition: all .45s ease-Out;
}

.circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    transition: all .5s ease-Out;
    top: 20px;
    left: 70px;
}

.container:hover .circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
}

.container:hover .iconContainer {
    z-index: 1;
}

.container:hover .title {
    z-index: 1;
}

.iconContainer {
    /* background: #fff; */

    /* color: var(--primary-color); */
    color: #b4b4b4;
    border-radius: 100%;
    width: 14px;
    height: 14px;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-shrink: 0;
    overflow: hidden;
}

.icon {
    transform: translateY(-3%);
}

.title {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    /* color: #fff; */
    color: #b4b4b4;

}

.withdrawIcon {
}

.depositIcon {
    transform: rotate(180deg);
}

.storeButton{
    background: var(--primary-color) !important;
    color: #fff !important;

}

.storeButton .title{
    color: #fff;
}