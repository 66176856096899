.flameContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .flame {
    position: relative;
    transform-origin: center bottom;
    animation: flameFlicker 1.5s ease-in-out infinite alternate;
  }
  
  .flamePath {
    animation: colorShift 2s ease-in-out infinite alternate;
  }
  
  @keyframes flameFlicker {
    0% {
      transform: scaleY(1) rotate(-1deg);
    }
    25% {
      transform: scaleY(1.05) rotate(1deg);
    }
    50% {
      transform: scaleY(0.95) rotate(-0.5deg);
    }
    75% {
      transform: scaleY(1.05) rotate(0.5deg);
    }
    100% {
      transform: scaleY(1) rotate(1deg);
    }
  }
  
  @keyframes colorShift {
    0% {
      opacity: 0.9;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.9;
    }
  }
  