.container {
    display: flex;
    align-items: center; 
    color: #fff;
    box-sizing: border-box; 
    /* padding: 5px 0;  */
}

.title {
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 11px;
    color: #ebe9e9;
    font-weight: 500;
    margin-right: 4px; 
}

.dots {
    flex-grow: 1;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.6); 
    margin: 0 4px; 
    height: 80%;
}

.value {
    white-space: nowrap;
    font-size: 14px;
    font-weight: bold; 
}