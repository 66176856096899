/* Основные стили контейнеров */
.mainContainer {
  position: relative;
}

.container {
  padding-top: 22px;
  position: relative;
}

/* Стили для фона карточки */
.backGradient {
  border-radius: 35px;
  width: 40vw;
  height: 30vw;
  margin: 42px auto 0 auto;
  position: absolute;
  top: -20px;
  pointer-events: none;
  will-change: transform, opacity;
}

.backGradientPizza {
  position: absolute;
  height: 90%;
  width: calc(100% - 32px);
  top: 0;
  left: 16px;
  opacity: 1;
  background: url('../../../../assets/923.jpg');
  background-position: center;
  background-size: cover;
  border-radius: 8px;
}

/* Стили для контейнера изображения */
.slider_image {
  position: relative;
  padding: 0 20px 12px;
  display: flex;
  justify-content: center;
}

/* Стили для карт */
.videocard {
  max-height: 30vh;
  width: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

/* Стили для заглушки, когда карта не выбрана */
.imageNotExist__container {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageNotExist {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  z-index: 2;
}

.duck {
  transform: translateX(-20px);
}

/* Стили для кнопки смены скина */
.buttonPallette {
  position: absolute;
  bottom: -22px;
  right: 20px;
  z-index: 2;
  cursor: pointer;
  /* background-color: rgba(255, 255, 255, 0.2); */
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

}



/* Анимации для слайдера */
@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-enter-right {
  animation: slideInRight 0.5s forwards;
  z-index: -1;
}

.slide-enter-left {
  animation: slideInLeft 0.5s forwards;
  z-index: -1;
}

/* Стили для лоадера */
.loader_icon {
  content: "";
  width: 25px;
  height: 25px;
  border: 5px solid #fff;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  transform: translateY(-50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}