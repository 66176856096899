.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradientBorder {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2px;
  border-radius: 16px;
  background-clip: padding-box;
  /* Удалено: transition: background 0.1s linear; */

  /* Используем CSS-переменную для угла */
  background: conic-gradient(from 0deg at 50% 50%,
      rgba(0, 151, 236, 1) 0deg,
      rgba(0, 151, 236, 0.5) calc(var(--angle) * 0.95),
      rgba(0, 151, 236, 0) var(--angle),
      transparent var(--angle) 360deg);

  /* Добавляем плавный переход для изменения угла */
  transition: all 0.016s linear;
  /* ~60 FPS */
}

.completeGradient {
  background: conic-gradient(from 0deg at 50% 50%,
      rgb(126, 211, 33) 0deg,
      rgba(126, 211, 33, 0.5) calc(var(--angle) * 0.95),
      rgba(126, 211, 33, 0) var(--angle),
      transparent var(--angle) 360deg);
}

.content {
  position: relative;
  /* Ваши стили для .content */
  box-sizing: border-box;
}