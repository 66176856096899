.coins_contaiener {
    display: flex;
    gap: 6px;
}

.coins {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.coins_title {
    font-size: 12px;
}

.coin {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    background: rgba(255, 255, 255, 0.05);
    font-size: 12px;
    border-radius: 4px;
}

.coin.selectedOption {
    background: #0097ec;
}