.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255,255,255, 0.05);
    border-radius: 8px;

    padding: 12px 16px;
}

.left {
    display: flex;
}

.title {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    font-weight: 500;
}

.wallet {
    font-size: 12px;
    color: #b4b4b4;
    margin-top: 8px;
}

.right {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
}

.date{
    font-size: 12px;
    text-align: center;
    color: #b4b4b4;
}

.spin {
    border: 4px solid transparent; /* Light white border */
    border-top: 2px solid white; /* Full white on top */
    border-left: 2px solid white; /* Full white on top */
    border-right: 2px solid white; /* Full white on top */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .trace_id{
    background: transparent;
    border: none;
    color: #b4b4b4;
    text-decoration: underline;
    margin-top: 5px;
  }