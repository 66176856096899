.container {
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.container:hover {
    background: rgba(255, 255, 255, 0.05);
    padding: 2px 8px;
    border-radius: 4px;
    color: #fff;
}

.active {
    background: rgba(255, 255, 255, 0.1);
    padding: 2px 8px;
    border-radius: 4px;
    color: #fff;
}