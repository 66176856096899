.main {
    height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    max-height: calc(100vh - 89.4px);
}



.minexDataIcon {
    color: #a026f8;
}

.titleBar {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 24px;
}

.titleBar .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #fff;
}


.stats {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.backLink {
    text-decoration: none;
}

.headerIcon{
    width: 28px;
    height: 28px;
    border-radius: 4px;
    background: rgba(160, 38, 248, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}