.mainContainer{
    /* background: radial-gradient(80.69% 84.06% at 81.94% 74.23%, rgb(49, 175, 141) 0%, rgb(0, 23, 9) 100%); */
    /* background: radial-gradient(142.63% 50% at 50% 100%, rgb(24, 98, 75) 0%, rgb(0, 0, 0) 100%); */
    /* background: url('../../assets/background.jpg'); */
    background: linear-gradient(178deg, rgba(3, 149, 235, 0.2) 0%, rgba(0, 0, 0, 0) 50%);
    min-height: 100vh;
    max-height: 100vh;
}

.container {
    padding: 24px 16px 200px;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    position: relative;
    z-index: 0;

}

.container::-webkit-scrollbar {
    display: none;
}



.pageHeader {
    font-weight: 500;
    font-size: 32px;
    line-height: 135%;
    color: #fff;
    text-transform: capitalize;
    max-width: 90%;

    margin: 40px 0 11px;
}

.pageDescription {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 22px;
}

.tasksContainer {
    display: flex;
    flex-direction: column;
}