.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.9);

    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    padding: 24px 16px;
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    /* gap: 10px; */
}

.modalOverlay:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.modal {
    /* height: 150px;s */
    width: 100%;

    /* background: lan#1c1c1e; */

    /* background: linear-gradient(180deg, rgba(0, 151, 236, 0.6) 0%, rgba(0, 151, 236, 0.4) 20%, rgba(26, 35, 52, 0.0) 60%, rgba(26, 35, 52, 0.0) 100%), #121212; */
    background: linear-gradient(180deg, rgb(0 151 236 / 38%) 0%, rgb(0 151 236 / 14%) 20%, rgba(26, 35, 52, 0.0) 60%, rgba(26, 35, 52, 0.0) 100%), #121212;
    /* linear-gradient(270deg, rgba(78, 21, 200, 0.6) 0%, rgba(78, 21, 200, 0.1) 100%) */
    background: linear-gradient(180deg, rgb(78 21 200 / 38%) 0%, rgb(78 21 200 / 14%) 20%, rgba(26, 35, 52, 0.0) 60%, rgba(26, 35, 52, 0.0) 100%), #121212;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    position: relative;



    padding: 20px 16px 24px;


    animation: slideUp 0.5s ease-out forwards;
}








/* Линия через ::before */
.modal::before {
    content: "";
    position: absolute;
    bottom: 0;
    /* отступ от низа блока */
    left: 0;
    width: 100%;
    height: 6px;
    /* толщина линии */

    /* 
       1) В качестве background используем встроенный SVG (data URI), 
          где у линии (stroke-dasharray) видны штрихи и «круглые» окончания (stroke-linecap="round").
       2) Чтобы был конкретный ритм (30px штрих + 10px пропуск = 40px на один «цикл»),
          рисуем svg шириной 40px, из которых первые 30px – это реальная чёрная линия, а 10px – пусто.
       3) repeat-x и background-size: 40px auto «тиражируют» этот узор по горизонтали на всю ширину.
    */
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='6'%3E%3Cpath d='M0 3 H30' stroke='%23000' stroke-width='6' stroke-linecap='round'/%3E%3C/svg%3E") repeat-x;
    background-size: 15% auto;
    background-position: -8%;
}


.modalBottom {

    width: 100%;

    background: #121212;
    position: relative;

    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    isolation: isolate;

    /* clip-path: 'M0.5,1 C0.5,1,0,0.7,0,0.3 A0.25,0.25,1,1,1,0.5,0.3 A0.25,0.25,1,1,1,1,0.3 C1,0.7,0.5,1,0.5,1 Z'; */
    /* clip-path: inset(20px 20px 50px round 15px); */
    /* clip-path: url(#modalCut); */

    /* clip-path: url("<svg width='344' height='154' viewBox='0 0 344 154' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M20.6323 153.978L0.000601162 153.978L5.64478e-05 16.0275C2.15555e-05 7.19096 7.18425 0.027496 16.0208 0.0273278C127.98 0.0252477 214.637 0.00220314 327.979 0.00015351C336.815 -1.47227e-05 344 7.16341 344 15.9999L344.001 154L319.725 154C319.725 150.554 317.918 147.25 314.701 144.813C311.484 142.377 307.12 141.008 302.571 141.008C298.021 141.008 293.658 142.377 290.441 144.813C287.224 147.25 285.416 150.554 285.416 154L275.608 154C275.608 150.553 273.8 147.247 270.581 144.809C267.363 142.372 262.998 141.003 258.446 141.003C253.894 141.003 249.529 142.372 246.311 144.809C243.093 147.247 241.285 150.553 241.285 154L231.476 154C231.476 150.553 229.668 147.247 226.449 144.809C223.231 142.372 218.866 141.003 214.314 141.003C209.763 141.003 205.398 142.372 202.179 144.809C198.961 147.247 197.153 150.553 197.153 154L187.344 154C187.344 150.553 185.536 147.247 182.317 144.809C179.099 142.372 174.734 141.003 170.182 141.003C165.631 141.003 161.266 142.372 158.047 144.809C154.829 147.247 153.021 150.553 153.021 154L143.212 154C143.215 151.192 142.017 148.458 139.797 146.209C137.577 143.96 134.454 142.316 130.897 141.524C129.327 141.165 127.697 140.98 126.058 140.975C123.803 140.975 121.571 141.312 119.489 141.965C117.406 142.619 115.514 143.576 113.92 144.784C112.326 145.991 111.062 147.425 110.2 149.002C109.338 150.58 108.895 152.271 108.896 153.978L99.0873 153.978C99.0873 150.531 97.2793 147.225 94.0608 144.788C90.8424 142.35 86.4773 140.981 81.9258 140.981C77.3742 140.981 73.0091 142.35 69.7907 144.788C66.5723 147.225 64.7641 150.531 64.7641 153.978L54.9555 153.978C54.9555 150.531 53.1473 147.225 49.9289 144.788C46.7105 142.35 42.3454 140.981 37.7938 140.981C33.2423 140.981 28.8772 142.35 25.6588 144.788C22.4403 147.225 20.6323 150.531 20.6323 153.978Z' fill='#D9D9D9'/></svg>"); */

    animation: slideUp 0.5s ease-out forwards;
}

.modalCut {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;

    width: 100%;
    height: 16px;

    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='80%' height='16'><path d='M0,25 A25,25 0 0 1 50,25' fill='%23000'/></svg>") repeat-x;
    background-size: 16% 100%;
    background-position: -16%;

    mix-blend-mode: destination-out;
}


.headerTitle {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    gap: 4px;
    align-items: center;
}

.headerTitle span {
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    font-size: 10px;
    padding: 4px 8px;
    border-radius: 4px;
}

.description {
    font-size: 12px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    line-height: 135%;
}

.ticketsCount {
    text-align: center;
    font-size: 12px;
    color: rgb(255, 108, 108);
}

.ticketsCount.buyed{
    color: rgb(155, 108, 255);

}

.button {
    padding: 12px 40px;
    width: fit-content;
    font-size: 12px;
}

.infoCards {
    display: flex;
    width: 100%;
    gap: 12px;
    margin-bottom: 22px;
    margin-top: 28px;
}

.infoCard {
    background: rgba(255, 255, 255, 0.1);
    flex: 1;
    text-align: center;
    padding: 12px 0;
    border-radius: 8px;
}

.infoCard .desc {
    font-size: 14px;
    color: #b4b4b4;
}

.carousel {
    min-height: 155px;
    display: flex;
    align-items: center;
    margin-top: 25px;
}

.slide {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 6px;
    position: relative;
    margin-bottom: 4px;
}

.info{
    position: absolute;
    right: -10px;
    top: 0;
    /* background: rgba(255, 255, 255, 0.1); */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(78, 21, 200,0.3);
    color: rgb(135, 80, 255);
    /* color: #fff; */
    font-size: 12px;
    font-weight: 600;
}

.footer {
    display: flex;

    align-items: center;
    gap: 12px;
}

.priceBlock {
    font-size: 16px;
}



















.navigation {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 0 30px;
    /* margin: 20px 0; */
}

.navRight {
    transform: rotate(180deg) scale(0.7);
    color: #b4b4b4;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
}

.navLeft {
    transform: scale(0.7);
    color: #b4b4b4;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
}



.payments_methods {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.payments_methods__description {
    font-size: 12px;
    line-height: 1.6;
    color: #b4b4b4;
    margin-top: 12px;
    margin-bottom: 16px;
    text-align: center;
}

.paymentButton {
    padding: 24px !important;
    border-radius: 8px !important;
    /* border: 0.1px solid #b4b4b4; */
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    background: rgba(255, 255, 255, 0.05) !important;

    cursor: pointer !important;
}

.paymentButton_Minex {
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.paymentButton .title {
    font-weight: 500;
    color: #b4b4b4;
    font-size: 14px;
}

.paymentButton .value {
    /* color: #FFC800; */
    display: flex;
    align-items: center;
    gap: 6px;

    font-size: 12px;
}

.paymentButton.stars {
    /* border-color: #FFC800;
    background: rgb(255, 200, 0, 0.05); */

}

.paymentButton.stars .title {
    /* color: #FFC800; */
}


.paymentButton.stars .value {
    /* color: #FFC800; */
}


.paymentButton.ton {
    border-color: #0098EA;
    /* background: rgb(0, 152, 234, 0.05);  */
}

.paymentButton.minex {
    /* border-color: #0097ec;
    background: rgb(0, 151, 236, 0.05); */
}





.countManipulate {
    display: flex;
    align-items: center;
    gap: 8px;
}

.countManipulate .button {
    width: 24px !important;
    height: 24px !important;
    padding: 0;
    /* border-radius: %; */
}

.countManipulate span {
    font-size: 22px;
}



.premiumText {
    color: #fff;
    font-weight: 600;
}

.premiumIconBg {
    /* color: #0097EC; */
    color: #0097EC;
    height: 80px;
}

.tonIcon{
    height: 65px;
    margin-bottom: 10px;
}

.ton {
    font-size: 32px;
    font-weight: 500;
}



@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideDown {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(100%);
        opacity: 0;
    }
}


.modalBottom.closing {
    animation: slideDown 0.5s ease-out forwards;
}

.modal.closing {
    animation: slideDown 0.5s ease-out forwards;
}


.point_navigation{
    display: flex;
    justify-content: center;
    gap: 4px;
    margin-bottom: 30px;
}

.point_navigation .point{
    width: 10px;
    height: 10px;
    background: #1d1d1e;
    border-radius: 50%;
}

.point_navigation .point.active{
    background: rgba(78, 21, 200);
}