.container {
    padding: 14px 16px;
    background: var(--bg-secondary-color);
    border-radius: 8px;
    text-decoration: none;
    /* border: 0.4px solid #333; */
    position: relative;
    overflow: hidden;
  }
  
  .titleContainer {
    display: flex;
    gap: 4px;
    align-items: center;
    position: relative;
  }
  
  .title {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .titleText {
    font-size: 14px;
    color: #fff;
    font-weight: 600;
  }
  
  .description {
    color: rgba(224, 224, 224, 0.6);
    font-size: 11px;
    margin-top: 6px;
    line-height: 12px;
  }
  
  .titleIcon {
    height: 26px;
    width: 26px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .additionalIcon {
    position: absolute;
    right: 0;
  }
  
  .arrow {
    height: 13px;
    width: 13px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .soon {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    font-size: 9px;
    color: #fff;
    padding: 2px 6px;
    font-weight: 500;
    vertical-align: top;
  }
  
 /* Star Effects */
.star {
    position: absolute;
    filter: blur(2px);
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    fill: linear-gradient(180deg, #ffb700 0%, #efa200 25%, #f87900 50%, #fb6f00 75%, #ff5e00 100%);
    transition: 0.2s all ease;
  }
  
  .star_1 {
    bottom: 0;
    left: 40%;
    height: 16px;
    transform: rotate(-61.84deg);
    opacity: 1;
    animation-name: flyRight1;
    animation-duration: 5s;
    animation-delay: -2s;
  }
  
  .star_2 {
    top: -16px;
    left: 45%;
    height: 32px;
    transform: rotate(-31.21deg);
    opacity: 0.2;
    animation-name: flyRight2;
    animation-duration: 6s;
    animation-delay: -3s;
  }
  
  .star_3 {
    bottom: 0;
    left: 53%;
    height: 11px;
    transform: rotate(-33.06deg);
    opacity: 0.1;
    animation-name: flyRight3;
    animation-duration: 4s;
    animation-delay: -1s;
  }
  
  .star_4 {
    top: 50%;
    left: 60%;
    width: 21px;
    height: 16px;
    transform: rotate(-33.06deg);
    opacity: 1;
    animation-name: flyRight4;
    animation-duration: 7s;
    animation-delay: -4s;
  }
  
  .star_5 {
    bottom: -10px;
    left: 70%;
    height: 24px;
    transform: rotate(-33.06deg);
    opacity: 1;
    animation-name: flyRight4;
    animation-duration: 5s;
    animation-delay: -2.5s;
  }
  
  .star_6 {
    top: 50%;
    left: 80%;
    height: 12px;
    transform: rotate(-33.06deg);
    opacity: 0.4;
    animation-name: flyRight4;
    animation-duration: 6.5s;
    animation-delay: -3.5s;
  }
  
  .star_7 {
    top: 0;
    left: 90%;
    height: 12px;
    transform: rotate(-33.06deg);
    opacity: 1;
    animation-name: flyRight4;
    animation-duration: 5.5s;
    animation-delay: -2s;
  }
  
  /* Keyframes for Each Star */
  @keyframes flyRight1 {
    0% {
      transform:  rotate(-61.84deg);
      opacity: 1;
      left: -10%;

    }
    100% {
      transform:  rotate(298.16deg);
      opacity: 0;
      left: 110%;

    }
  }
  
  @keyframes flyRight2 {
    0% {
      transform: rotate(-31.21deg);
      opacity: 0.2;
      left: -10%;

    }
    100% {
      transform: rotate(328.79deg);
      opacity: 0;
      left: 110%;

    }
  }
  
  @keyframes flyRight3 {
    0% {
      transform: rotate(-33.06deg);
      opacity: 0.1;
      left: -10%;

    }
    100% {
      transform: rotate(326.94deg);
      opacity: 0;
      left: 110%;

    }
  }
  
  @keyframes flyRight4 {
    0% {
      transform: rotate(-33.06deg);
      opacity: inherit;
      left: -10%;

    }
    100% {
      transform:  rotate(326.94deg);
      opacity: 0;
      left: 110%;

    }
  }