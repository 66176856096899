.card {
    backdrop-filter: blur(4px);
    border-radius: 12px;

    display: flex;
    align-items: center;
    gap: 14px;

}

.iconContainer {
    border-radius: 12px;
    width: 55px;
    height: 55px;
    background: var(--bg-secondary-color);
    display: flex;
    color: rgba(255, 255, 255, 1);
    align-items: center;
    justify-content: center;
}

.rightSide {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 0.3px solid rgba(255,255,255,0.2);
    flex: 1;
}
.info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;
 
}

.info__name {
    font-weight: 600;
    font-size: 15px;
    color: rgba(255, 255, 255, 1);
    text-transform: capitalize;
}

.info__reward {
    font-weight: 400;
    font-size: 14px;
    color: #ffe03b;

    display: flex;
    align-items: center;
    gap: 6px;
}

.checkMark{
    color: #fff;
    background: #0395EB;
    padding: 8px 12px;
    font-size: 10px;
    text-transform: capitalize;
    font-weight: 700;
    border-radius: 6px;
    cursor: pointer;
}

.spinner {
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-top: 2px solid white;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }