.poolBoost {
    display: flex;
    gap: 6px;
    justify-content: flex-end;
    flex: 1;
}

.poolBoost .boost {
    font-size: 12px;
    display: flex;
    align-items: center;

}

.poolBoost .icon {
    width: 24px;
    height: 24px;
    border-radius: 16px;
    background: #000;
}