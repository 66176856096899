.container {
    display: flex;
    flex-direction: column;
    position: relative;
}

/* .container::before {
    content: '';
    background: rgba(0, 0, 0, 0.9);
    -webkit-filter: blur(40px);
    filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
} */

.label {
    position: absolute;

    left: 10px;
    top: -10px;

    font-weight: 500;
    font-size: 15px;
    line-height: 147%;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.6);

    padding: 0 10px;
    z-index: 1;
}

.input {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #fff;

    border: 1.80px solid rgba(255, 255, 255, 0.05);
    border-radius: 14px;
    padding: 16px;

    background: transparent;

    /* background: rgba(255,255,255,0.2); */

    transition: 0.2s all ease-in-out;
    box-sizing: border-box;
    position: relative;
}

/* .input::before {
    content: '';
    background: rgba(0, 0, 0, 0.1);
    -webkit-filter: blur(40px);
    filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
} */