.main {
    display: flex;
    flex-direction: column;
    gap: 25px;
    overflow-y: scroll;
    max-height: calc(100vh - 89.4px);
    padding: 18px 16px 24px;



}

.main::-webkit-scrollbar {
    display: none;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    /* margin-bottom: 50px; */
}

.header .title {
    color: #66FF00;
    display: flex;
    align-items: center;
    gap: 8px;
}

.header .title div {
    font-weight: 400;
    font-size: 22px;
    line-height: 75%;
    text-align: center;
    color: #66FF00;
}

.header .title .icon {
    width: 28px;
    height: 28px;
    border-radius: 4px;
    background: #66ff0037;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header .description {
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #ffffffb5;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.giftCard {
    background: var(--bg-secondary-color);
    border-radius: 8px;
    padding: 12px 16px;

    display: flex;
    gap: 12px;
    align-items: center;
}

.received {
    border-left: 1px solid #66FF00 !important;
    background: linear-gradient(45deg, #66ff0024, transparent);
}

.sended {
    border-left: 1px solid var(--primary-color) !important;
    background: linear-gradient(45deg, #0097ec47, transparent);
}


.giftCard .img {
    max-width: 80px;
}

.giftCard .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.giftCard .content .card_buttons {
    display: flex;
    gap: 4px;
    flex-direction: row-reverse;
}

.giftCard .content .title {
    font-size: 14px;
    margin-bottom: 6px;
}


.giftCard .content .card_buttons .button {
    width: fit-content;
    padding: 6px 16px;
    font-size: 12px;
    background: #0097ec21;
    color: var(--primary-color);
}

.giftCard .content .card_buttons .button svg {
    height: 14px;
    width: 14px;
}


.backLink {
    text-decoration: none;
}

/* ------- */
.buttonsContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 24px 16px;
    background: rgba(0, 0, 0, 0.70);
}