.pool {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.pool:hover{
    transform: translateY(-10px);

}

.icon {
    border: 2px solid;
    position: relative;
    border-radius: 50%;
    background: #000;
}


.bronze,
.silver {
    width: 25vw ;
    height: 25vw ;
}

.bronze {
    border-color: #8A5100;
    box-shadow: 0 0 5px 2px rgba(138, 81, 0, 0.45);
}

.silver {
    border-color: #7F7F7F;
    box-shadow: 0 0 5px 2px rgba(127, 127, 127, 0.25);
}

.pool .golden {
    width: 30vw;
    height: 30vw;
    border-color: #FFB700;
    box-shadow: 0 0 5px 2px rgba(255, 183, 0, 0.25);
}

.pool .golden {
    position: relative;
    /* Необходимо для позиционирования псевдоэлемента, если используется */
    /* Другие стили основного элемента */
}

.pool .golden::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 20px;
    height: 19px;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%229%22%20viewBox%3D%220%200%2010%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M5.33394%200.194349C5.26548%200.0741651%205.13805%200%204.99999%200C4.86199%200%204.73456%200.0741651%204.66605%200.194349L2.78083%203.50294L0.52747%202.59901C0.393362%202.54522%200.240511%202.57131%200.131701%202.66656C0.0228909%202.76181%20-0.0235347%202.91017%200.011496%203.05069L1.62279%209H8.37722L9.9885%203.05069C10.0235%202.91017%209.97712%202.76181%209.8683%202.66656C9.75948%202.57131%209.60666%202.54522%209.47256%202.59901L7.21917%203.50294L5.33394%200.194349Z%22%20fill%3D%22%23FFB700%22/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    /* Дополнительные стили по необходимости */
}

.pool .icon .lvl {
    width: 32px;
    height: 32px;
    color: #000;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.pool .icon .golden::after {
    content: none;
}

.pool .icon .bronze {
    background: #8A5100;
}

.pool .icon .silver {
    background: #7F7F7F;

}

.pool .icon .golden {
    background: #FFB700;
    color: #000;

}

.pool .name {
    margin-top: 16px;
    font-size: 14px;
    text-decoration: none;
}

.pool .ths {
    margin-top: 2px;
    font-size: 12px;
    color: #b4b4b4;
}


.pool .buttons {
    display: flex;
    gap: 2px;
    margin: 16px 0 0;
    font-size: 12px;
    width: 100%;
    gap: 4px;
    padding: 0 16px;

    justify-content: center;
}

.pool .buttons .join {
    background: #0097EC;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 4px 0;
    font-weight: 700;
    border-radius: 4px;
}

.pool .buttons .view {
    background: rgb(0, 151, 236, 0.2);
    color: #0097EC;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 4px 0;
}

/*  .pool .buttons .info {
    width: 20px;
    height: 20px;
    background: rgb(0, 151, 236, 0.2);
    color: #0097EC;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
} */

.pool .name {
    margin-top: 16px;
    font-size: 14px;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
}

.pool .goldenName {
    max-width: 30vw;
}

.pool .otherName {
    max-width: 25vw;
}

.marquee {
    display: inline-block;
    white-space: nowrap;
    /* animation-delay: 5s; */
    animation: marquee 12s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translateX(0%);
    }

    50% {
        transform: translateX(-100%);
        opacity: 1;
    }

    50.01% {
        transform: translateX(-100%);
        opacity: 0;
    }

    50.02% {
        transform: translateX(100%);
        opacity: 0;
    }

    50.03% {
        transform: translateX(90%);
        opacity: 1;
    }

    100% {
        transform: translateX(0%);
    }
}

