.balanceContainer {
    /* backdrop-filter: blur(87.30000305175781px); */
    /* background:  var(--primary-color); */
    border-radius: 12px;
    display: flex;
    margin: 0 40px;
    gap: 12px;

    align-content: center;
    justify-content: space-between;
    cursor: pointer;
    backdrop-filter: blur(100px);
    z-index: 1;
}

.balanceContainer__balance {
    /* background: #000000; */
    border-radius: 6px;
    /* border: 0.2px solid var(--primary-color); */
    color: #fff;
    padding: 5px 30px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #fff;

    gap: 6px;
    background: rgba(255, 255, 255, 0.05);

}

.balanceContainer__left {
    width: 35px;
    height: 35px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.05);
}

.balanceContainer__right {
    width: 35px;
    height: 35px;

    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;

}



.wallet_container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 100px;
}

.coin_container {
    display: flex;
    align-items: center;
    background: #161616;
    padding: 14px 16px;
    border-radius: 8px;

    flex: 2;
}

.coin_container .value {
    flex: 2;
    color: #d2d2d2;
    padding: 0 10px 0;
    font-size: 14px;
}

.coin_container .coin {
    display: flex;
    align-items: center;
    color: #d2d2d2;
    font-size: 14px;
    line-height: 18px;
    gap: 7px;

    flex: 1;
}

.coin_container .infoIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 1px solid #999;
    color: #999;
    font-size: 12px;
    font-weight: 600;

}