.overlay {
    top: 0;
    left: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1001;

}

.overlay::before {
    content: "";
    width: 100%;
    height: 100%;
    z-index: -1;
    -webkit-backdrop-filter: blur(10px);
    filter: blur(10px);


    position: absolute;
    background: rgba(0, 0, 0, 0.05);
}

.modal {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 90vw;
    background: #000;
    /* background-image: url("data:image/svg+xml,%3Csvg width='39' height='39' opacity='1' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1802_6732)'%3E%3Crect width='39' height='39'  fill='white'/%3E%3Cpath d='M-0.000960425 0.000960414L9.74354 0.000371075L-14.6546 24.3759L-19.5402 19.4904L-0.000960425 0.000960414Z' fill='%238336FF'/%3E%3Crect x='24.835' y='-15.1152' width='6.90922' height='55.8472' transform='rotate(45 24.835 -15.1152)' fill='%23601EC9'/%3E%3Crect x='29.7236' y='-10.2266' width='6.90922' height='55.8472' transform='rotate(45 29.7236 -10.2266)' fill='%238336FF'/%3E%3Cpath d='M29.3 -0.000488303L38.9995 -0.000488288L0.00355687 39.0332L-4.882 34.1476L29.3 -0.000488303Z' fill='%23601EC9'/%3E%3Cpath d='M39 0L39 10L10 39L0.0320622 39.0002L39 0Z' fill='%238336FF'/%3E%3Cpath d='M39.0002 9.80196L39.0001 19.4999L20.0001 38.9999L9.80062 38.9992L39.0002 9.80196Z' fill='%23601EC9'/%3E%3Cpath d='M39 19.5L39.0005 29.5L29.4995 38.9995L19.5703 39.001L39 19.5Z' fill='%238336FF'/%3E%3Cpath d='M38.9997 29.3191L38.9997 39.0002H29.2996L38.9997 29.3191Z' fill='%23601EC9'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1802_6732'%3E%3Crect width='39' height='39' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A"); */
    background-size: 75px 75px;
    -webkit-animation: bscale 50s infinite linear;
    animation: bscale 50s infinite linear;
    border-radius: 16px;
    overflow: hidden;
    padding: 16px;
    animation: slideUp 0.5s ease-out forwards;


}

.modal.red {
    background-image: url("data:image/svg+xml,%3Csvg width='39' height='39' opacity='1' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1802_6732)'%3E%3Crect width='39' height='39'  fill='white'/%3E%3Cpath d='M-0.000960425 0.000960414L9.74354 0.000371075L-14.6546 24.3759L-19.5402 19.4904L-0.000960425 0.000960414Z' fill='%238336FF'/%3E%3Crect x='24.835' y='-15.1152' width='6.90922' height='55.8472' transform='rotate(45 24.835 -15.1152)' fill='%23601EC9'/%3E%3Crect x='29.7236' y='-10.2266' width='6.90922' height='55.8472' transform='rotate(45 29.7236 -10.2266)' fill='%238336FF'/%3E%3Cpath d='M29.3 -0.000488303L38.9995 -0.000488288L0.00355687 39.0332L-4.882 34.1476L29.3 -0.000488303Z' fill='%23601EC9'/%3E%3Cpath d='M39 0L39 10L10 39L0.0320622 39.0002L39 0Z' fill='%238336FF'/%3E%3Cpath d='M39.0002 9.80196L39.0001 19.4999L20.0001 38.9999L9.80062 38.9992L39.0002 9.80196Z' fill='%23601EC9'/%3E%3Cpath d='M39 19.5L39.0005 29.5L29.4995 38.9995L19.5703 39.001L39 19.5Z' fill='%238336FF'/%3E%3Cpath d='M38.9997 29.3191L38.9997 39.0002H29.2996L38.9997 29.3191Z' fill='%23601EC9'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1802_6732'%3E%3Crect width='39' height='39' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");

}

.modal::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

}




@-webkit-keyframes bscale {
    0% {
        background-position: 0px 0px;
    }

    100% {
        background-position: 100% 100%;
    }
}


@keyframes bscale {
    0% {
        background-position: 0px 0px;
    }

    100% {
        background-position: 100% 100%;
    }
}

.winContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 48px;
    font-weight: 600;

    display: flex;
    gap: 6px;

    /* animation: float 3s ease-in-out infinite; */
}

/* .winContainer::before {
    content: '';
    background: #00000058;
    width: 80px;
    height: 80px;
    border-radius: 80px;
    position: absolute;
    z-index: -1;

    filter: blur(10px);
} */

.title {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #fff;
    margin-bottom: 6px;
}

.description {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
    line-height: 135%;
    padding: 0 20px;
}

.button {
    background: #000;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
}


@keyframes float {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

















.gradientPink {
    background-image: url("data:image/svg+xml,%3Csvg width='39' height='39' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1890_96)'%3E%3Crect width='39' height='39' fill='white'/%3E%3Cpath d='M-0.000960425 0.000960414L9.74354 0.000371075L-14.6546 24.3759L-19.5402 19.4904L-0.000960425 0.000960414Z' fill='%23FF0080'/%3E%3Crect x='24.835' y='-15.1152' width='6.90922' height='55.8472' transform='rotate(45 24.835 -15.1152)' fill='%23C50062'/%3E%3Crect x='29.7236' y='-10.2266' width='6.90922' height='55.8472' transform='rotate(45 29.7236 -10.2266)' fill='%23FF0080'/%3E%3Cpath d='M29.3 -0.000488303L38.9995 -0.000488288L0.00355687 39.0332L-4.882 34.1476L29.3 -0.000488303Z' fill='%23C50062'/%3E%3Cpath d='M39 0L39 10L10 39L0.0320622 39.0002L39 0Z' fill='%23FF0080'/%3E%3Cpath d='M39.0002 9.80196L39.0001 19.4999L20.0001 38.9999L9.80062 38.9992L39.0002 9.80196Z' fill='%23C50062'/%3E%3Cpath d='M39 19.5L39.0005 29.5L29.4995 38.9995L19.5703 39.001L39 19.5Z' fill='%23FF0080'/%3E%3Cpath d='M38.9997 29.3191L38.9997 39.0002H29.2996L38.9997 29.3191Z' fill='%23C50062'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1890_96'%3E%3Crect width='39' height='39' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    /* background-image: url("data:image/svg+xml,%3Csvg width='39' height='39' opacity='1' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1802_6732)'%3E%3Crect width='39' height='39'  fill='white'/%3E%3Cpath d='M-0.000960425 0.000960414L9.74354 0.000371075L-14.6546 24.3759L-19.5402 19.4904L-0.000960425 0.000960414Z' fill='%23FF389B'/%3E%3Crect x='24.835' y='-15.1152' width='6.90922' height='55.8472' transform='rotate(45 24.835 -15.1152)' fill='%23FF007F'/%3E%3Crect x='29.7236' y='-10.2266' width='6.90922' height='55.8472' transform='rotate(45 29.7236 -10.2266)' fill='%23FF389B'/%3E%3Cpath d='M29.3 -0.000488303L38.9995 -0.000488288L0.00355687 39.0332L-4.882 34.1476L29.3 -0.000488303Z' fill='%23FF007F'/%3E%3Cpath d='M39 0L39 10L10 39L0.0320622 39.0002L39 0Z' fill='%23FF389B'/%3E%3Cpath d='M39.0002 9.80196L39.0001 19.4999L20.0001 38.9999L9.80062 38.9992L39.0002 9.80196Z' fill='%23FF007F'/%3E%3Cpath d='M39 19.5L39.0005 29.5L29.4995 38.9995L19.5703 39.001L39 19.5Z' fill='%23FF389B'/%3E%3Cpath d='M38.9997 29.3191L38.9997 39.0002H29.2996L38.9997 29.3191Z' fill='%23FF007F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1802_6732'%3E%3Crect width='39' height='39' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A"); */
}

.gradientPink::after {
    background: linear-gradient(360deg, rgba(197, 0, 98, 0.9) 30%, rgba(0, 0, 0, 0.3) 100%);
}




.gradientGray {
    /* background-image: url("data:image/svg+xml,%3Csvg width='39' height='39' opacity='1' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1802_6732)'%3E%3Crect width='39' height='39'  fill='white'/%3E%3Cpath d='M-0.000960425 0.000960414L9.74354 0.000371075L-14.6546 24.3759L-19.5402 19.4904L-0.000960425 0.000960414Z' fill='%234D4D4D'/%3E%3Crect x='24.835' y='-15.1152' width='6.90922' height='55.8472' transform='rotate(45 24.835 -15.1152)' fill='%23383838'/%3E%3Crect x='29.7236' y='-10.2266' width='6.90922' height='55.8472' transform='rotate(45 29.7236 -10.2266)' fill='%234D4D4D'/%3E%3Cpath d='M29.3 -0.000488303L38.9995 -0.000488288L0.00355687 39.0332L-4.882 34.1476L29.3 -0.000488303Z' fill='%23383838'/%3E%3Cpath d='M39 0L39 10L10 39L0.0320622 39.0002L39 0Z' fill='%234D4D4D'/%3E%3Cpath d='M39.0002 9.80196L39.0001 19.4999L20.0001 38.9999L9.80062 38.9992L39.0002 9.80196Z' fill='%23383838'/%3E%3Cpath d='M39 19.5L39.0005 29.5L29.4995 38.9995L19.5703 39.001L39 19.5Z' fill='%234D4D4D'/%3E%3Cpath d='M38.9997 29.3191L38.9997 39.0002H29.2996L38.9997 29.3191Z' fill='%23383838'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1802_6732'%3E%3Crect width='39' height='39' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A"); */
    background-image: url("data:image/svg+xml,%3Csvg width='39' height='39' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1854_8902)'%3E%3Crect width='39' height='39' fill='white'/%3E%3Cpath d='M-0.000960425 -1.61483e-05L9.74354 -0.000605488L-14.6546 24.375L-19.5402 19.4894L-0.000960425 -1.61483e-05Z' fill='%234D4D4D'/%3E%3Cpath d='M24.8345 -15.1157L30 -10L-9.48991 29.4899L-14.6554 24.3742L24.8345 -15.1157Z' fill='%23383838'/%3E%3Cpath d='M29.7236 -10.2266L35 -4.5L-4.48991 34.9899L-9.76628 29.2633L29.7236 -10.2266Z' fill='%234D4D4D'/%3E%3Cpath d='M29.3 -0.000488303L38.9995 -0.000488288L1.35995e-06 40L-4.882 34.1476L29.3 -0.000488303Z' fill='%23383838'/%3E%3Cpath d='M39 -0.000976562L39 9.99902L10 38.999L0.0320622 38.9992L39 -0.000976562Z' fill='%234D4D4D'/%3E%3Cpath d='M38.9992 9.80196L38.9991 19.4999L19.9991 38.9999L9.79964 38.9992L38.9992 9.80196Z' fill='%23383838'/%3E%3Cpath d='M39 19.5L39.0005 29.5L29.4995 38.9995L19.5703 39.001L39 19.5Z' fill='%234D4D4D'/%3E%3Cpath d='M38.9997 29.3181L38.9997 38.9992H29.2996L38.9997 29.3181Z' fill='%23383838'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1854_8902'%3E%3Crect width='39' height='39' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.gradientGray::after {
    background: linear-gradient(360deg, rgba(77, 77, 77, 0.8) 30%, rgba(0, 0, 0, 0.3) 100%);
}


.gradientYellow {
    background-image: url("data:image/svg+xml,%3Csvg width='39' height='39' opacity='1' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1802_6732)'%3E%3Crect width='39' height='39'  fill='white'/%3E%3Cpath d='M-0.000960425 0.000960414L9.74354 0.000371075L-14.6546 24.3759L-19.5402 19.4904L-0.000960425 0.000960414Z' fill='%23FFA800'/%3E%3Crect x='24.835' y='-15.1152' width='6.90922' height='55.8472' transform='rotate(45 24.835 -15.1152)' fill='%23DE9300'/%3E%3Crect x='29.7236' y='-10.2266' width='6.90922' height='55.8472' transform='rotate(45 29.7236 -10.2266)' fill='%23FFA800'/%3E%3Cpath d='M29.3 -0.000488303L38.9995 -0.000488288L0.00355687 39.0332L-4.882 34.1476L29.3 -0.000488303Z' fill='%23DE9300'/%3E%3Cpath d='M39 0L39 10L10 39L0.0320622 39.0002L39 0Z' fill='%23FFA800'/%3E%3Cpath d='M39.0002 9.80196L39.0001 19.4999L20.0001 38.9999L9.80062 38.9992L39.0002 9.80196Z' fill='%23DE9300'/%3E%3Cpath d='M39 19.5L39.0005 29.5L29.4995 38.9995L19.5703 39.001L39 19.5Z' fill='%23FFA800'/%3E%3Cpath d='M38.9997 29.3191L38.9997 39.0002H29.2996L38.9997 29.3191Z' fill='%23DE9300'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1802_6732'%3E%3Crect width='39' height='39' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.gradientYellow::after {
    background: linear-gradient(360deg, rgba(255, 162, 0, 0.8) 30%, rgba(0, 0, 0, 0.3) 100%);
}


.gradientBlue {
    background-image: url("data:image/svg+xml,%3Csvg width='39' height='39' opacity='1' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1802_6732)'%3E%3Crect width='39' height='39'  fill='white'/%3E%3Cpath d='M-0.000960425 0.000960414L9.74354 0.000371075L-14.6546 24.3759L-19.5402 19.4904L-0.000960425 0.000960414Z' fill='%23363AFF'/%3E%3Crect x='24.835' y='-15.1152' width='6.90922' height='55.8472' transform='rotate(45 24.835 -15.1152)' fill='%230C10E0'/%3E%3Crect x='29.7236' y='-10.2266' width='6.90922' height='55.8472' transform='rotate(45 29.7236 -10.2266)' fill='%23363AFF'/%3E%3Cpath d='M29.3 -0.000488303L38.9995 -0.000488288L0.00355687 39.0332L-4.882 34.1476L29.3 -0.000488303Z' fill='%230C10E0'/%3E%3Cpath d='M39 0L39 10L10 39L0.0320622 39.0002L39 0Z' fill='%23363AFF'/%3E%3Cpath d='M39.0002 9.80196L39.0001 19.4999L20.0001 38.9999L9.80062 38.9992L39.0002 9.80196Z' fill='%230C10E0'/%3E%3Cpath d='M39 19.5L39.0005 29.5L29.4995 38.9995L19.5703 39.001L39 19.5Z' fill='%23363AFF'/%3E%3Cpath d='M38.9997 29.3191L38.9997 39.0002H29.2996L38.9997 29.3191Z' fill='%230C10E0'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1802_6732'%3E%3Crect width='39' height='39' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.gradientBlue::after {
    background: linear-gradient(360deg, rgba(54, 58, 255, 0.8) 30%, rgba(0, 0, 0, 0.3) 100%);
}

.gradientPurple {
    background-image: url("data:image/svg+xml,%3Csvg width='39' height='39' opacity='1' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1802_6732)'%3E%3Crect width='39' height='39'  fill='white'/%3E%3Cpath d='M-0.000960425 0.000960414L9.74354 0.000371075L-14.6546 24.3759L-19.5402 19.4904L-0.000960425 0.000960414Z' fill='%238336FF'/%3E%3Crect x='24.835' y='-15.1152' width='6.90922' height='55.8472' transform='rotate(45 24.835 -15.1152)' fill='%23601EC9'/%3E%3Crect x='29.7236' y='-10.2266' width='6.90922' height='55.8472' transform='rotate(45 29.7236 -10.2266)' fill='%238336FF'/%3E%3Cpath d='M29.3 -0.000488303L38.9995 -0.000488288L0.00355687 39.0332L-4.882 34.1476L29.3 -0.000488303Z' fill='%23601EC9'/%3E%3Cpath d='M39 0L39 10L10 39L0.0320622 39.0002L39 0Z' fill='%238336FF'/%3E%3Cpath d='M39.0002 9.80196L39.0001 19.4999L20.0001 38.9999L9.80062 38.9992L39.0002 9.80196Z' fill='%23601EC9'/%3E%3Cpath d='M39 19.5L39.0005 29.5L29.4995 38.9995L19.5703 39.001L39 19.5Z' fill='%238336FF'/%3E%3Cpath d='M38.9997 29.3191L38.9997 39.0002H29.2996L38.9997 29.3191Z' fill='%23601EC9'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1802_6732'%3E%3Crect width='39' height='39' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.gradientPurple::after {
    background: linear-gradient(360deg, rgba(131, 54, 255, 0.8) 30%, rgba(0, 0, 0, 0.3) 100%);
}

.gradientRed {
    background-image: url("data:image/svg+xml,%3Csvg width='39' height='39' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1890_72)'%3E%3Crect width='39' height='39' fill='white'/%3E%3Cpath d='M1.58628e-07 -0.5L10.5 -0.5L-14.6517 24.3779L-19.5373 19.4924L1.58628e-07 -0.5Z' fill='%23DD261C'/%3E%3Crect x='24.8369' y='-15.1123' width='7.23334' height='55.8472' transform='rotate(45 24.8369 -15.1123)' fill='%23B01E16'/%3E%3Crect x='29.7256' y='-10.2236' width='6.90922' height='55.8472' transform='rotate(45 29.7256 -10.2236)' fill='%23DD261C'/%3E%3Cpath d='M31.6258 -2.37462L41.7245 -2.64265L0.0447568 39.0754L-4.9069 34.1237L31.6258 -2.37462Z' fill='%23B01E16'/%3E%3Cpath d='M39.002 0.00292969L39.002 10.0029L10.002 39.0029L0.0340153 39.0031L39.002 0.00292969Z' fill='%23DD261C'/%3E%3Cpath d='M39.0022 9.80489L39.002 19.5028L20.002 39.0028L9.80257 39.0021L39.0022 9.80489Z' fill='%23B01E16'/%3E%3Cpath d='M39.002 19.5029L39.0024 29.5029L29.5015 39.0024L19.5723 39.0039L39.002 19.5029Z' fill='%23DD261C'/%3E%3Cpath d='M39.0017 29.322L39.0017 39.0031H29.3016L39.0017 29.322Z' fill='%23B01E16'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1890_72'%3E%3Crect width='39' height='39' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    /* background-image: url("data:image/svg+xml,%3Csvg width='39' height='39' opacity='1' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1802_6732)'%3E%3Crect width='39' height='39'  fill='white'/%3E%3Cpath d='M-0.000960425 0.000960414L9.74354 0.000371075L-14.6546 24.3759L-19.5402 19.4904L-0.000960425 0.000960414Z' fill='%23DD261C'/%3E%3Crect x='24.835' y='-15.1152' width='6.90922' height='55.8472' transform='rotate(45 24.835 -15.1152)' fill='%23B01E16'/%3E%3Crect x='29.7236' y='-10.2266' width='6.90922' height='55.8472' transform='rotate(45 29.7236 -10.2266)' fill='%23DD261C'/%3E%3Cpath d='M29.3 -0.000488303L38.9995 -0.000488288L0.00355687 39.0332L-4.882 34.1476L29.3 -0.000488303Z' fill='%23B01E16'/%3E%3Cpath d='M39 0L39 10L10 39L0.0320622 39.0002L39 0Z' fill='%23DD261C'/%3E%3Cpath d='M39.0002 9.80196L39.0001 19.4999L20.0001 38.9999L9.80062 38.9992L39.0002 9.80196Z' fill='%23B01E16'/%3E%3Cpath d='M39 19.5L39.0005 29.5L29.4995 38.9995L19.5703 39.001L39 19.5Z' fill='%23DD261C'/%3E%3Cpath d='M38.9997 29.3191L38.9997 39.0002H29.2996L38.9997 29.3191Z' fill='%23B01E16'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1802_6732'%3E%3Crect width='39' height='39' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A"); */
}

.gradientRed::after {
    background: linear-gradient(360deg, rgba(255, 43, 31, 0.7) 30%, rgba(0, 0, 0, 0.3) 100%);
}

/* .winnerRing {
      box-shadow: 0 0 0 4px rgba(34, 197, 94, 0.5); 
    } */

.image {
    width: 100%;
    max-width: 60%;
    object-fit: contain;
    padding: 5px;
    user-select: none;
    pointer-events: none;
}



.winnerOverlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* background-color: rgba(34, 197, 94, 0.2);  */
    pointer-events: none;
}

.premiumIconBg {
    color: #fff;
}

.premiumText{
    display: flex;
    gap: 6px;
    align-items: flex-end;
    font-size: 34px;
    font-weight: 500;
}

.modal.closing{
    animation: slideDown 0.5s ease-out forwards;
}

@keyframes slideUp {
    from {
        top: 50%;
        left: 50%;
        transform: translateY(150%) translateX(-50%);
        opacity: 0;
    }

    to {
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        opacity: 1;
    }
}

@keyframes slideDown {
    from {
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        opacity: 1;
    }

    to {
        top: 50%;
        left: 50%;
        transform: translateY(150%) translateX(-50%);
        opacity: 0;
    }
}
