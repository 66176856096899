.skeleton {
    background: rgba(130, 130, 130, 0.2);
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation: wave 2s infinite ease-out;
    border-radius: 5px;

    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    flex-shrink: 0;
}

@keyframes wave {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}