.coinContainer {
    position: relative;
    perspective: 500px;
    display: inline-block;
  }
  
  .coin {
    border-radius: 50%;
    display: block;
    transform-style: preserve-3d;
    transform: rotateY(0deg);
  }
  
  .coin3d {
    border-radius: 50%;
    transform-style: preserve-3d;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .coinEdge {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    z-index: -1;
  }
  
  .goldEdge {
    background: #002f47;
    /* background: #B8AD6A; */
  }
  
  .tonEdge {
    background: #00547e;
  }
  
  .thingsEdge {
    background: #272500;
  }
  
  @keyframes coinTilt {
    0%, 100% { transform: rotateY(0deg); }
    25% { transform: rotateY(25deg); }
    75% { transform: rotateY(-25deg); }
  }
  
  .animated {
    animation: coinTilt 3s ease-in-out infinite;
  }


  .minexCoin{
    background: #00547e;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

  }