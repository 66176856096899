/* Container */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: #0A0A0A;
    color: white;

}

/* Circle container */
.circleContainer {
    position: relative;
    width: 8rem;
    height: 8rem;
    margin-bottom: 1rem;
}

/* Background circle */
/* .backgroundCircle {
    position: absolute;
    inset: 0;
    border-radius: 9999px;
    background-color: #1c1c1e;
    width: 8rem;
    height: 8rem;
} */

/* Rotating arc container */
.arcContainer {
    position: absolute;
    inset: 0;
}

.arcSvg {
    position: absolute;
    inset: 0;
}

/* Pointer triangle */
.pointerTriangle {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%) translateY(25%) rotate(180deg);
    z-index: 10;
    color: #fff;
}

/* Video card placeholder */
.videoCardContainer {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vcard {
    width: 50%;
}

.videoCard {
    width: 10rem;
    height: 6rem;
    background-color: #374151;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardElement {
    position: relative;
    width: 100%;
    height: 100%;
}

.cardFan1 {
    position: absolute;
    left: 0.25rem;
    top: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 9999px;
    background-color: #4b5563;
}

.cardFan2 {
    position: absolute;
    right: 0.25rem;
    top: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 9999px;
    background-color: #4b5563;
}

.cardConnector {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    width: 4rem;
    height: 0.5rem;
    background-color: #fbbf24;
}

.cardBrand {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: white;
    font-size: 0.875rem;
}

/* Result overlay */
.resultOverlay {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    background-color: rgba(0, 0, 0, 0.7);
}

.resultText {
    font-size: 1.875rem;
    font-weight: 700;
    text-align: center;
}

.winText {
    color: #4ade80;
}

.loseText {
    color: #ef4444;
}

/* Price display */
.priceDisplay {
    font-size: 1.25rem;
    margin-bottom: 40px;
    text-align: center;
}

.priceLabel {
    color: #9ca3af;
    font-size: 16px;
}

.priceValue {
    color: #0097ec;
    font-size: 10px;
    background: rgba(0, 151, 236,0.1);
    padding: 4px 8px;
    border-radius: 8px;
}

/* Percentage slider */
.sliderContainer {
    width: 100%;
    max-width: 28rem;
    margin-bottom: 2rem;
}

.sliderLabel {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;

}

.sliderTrack {
    position: relative;
    height: 1.5rem;
    width: 100%;
    background-color: #1c1c1e;
    border-radius: 0.375rem;
}

.sliderProgress {
    position: absolute;
    height: 100%;
    background-color: #0097ec;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}

.sliderMarkers {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    padding: 0 25%;
}

.sliderDot {
    width: 8px;
    height: 8px;
    background-color: white;
    border-radius: 9999px;
    cursor: pointer;
    z-index: 10;
}

.sliderInput {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

/* Controls */
.controlsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 28rem;
    gap: 1rem;
    margin-bottom: 2rem;
}


.priceBlock {
    width: 100%;
}

.chanceBlock {
    width: 100%;
}

.controlLabel {
    color: #9ca3af;
    margin-bottom: 0.5rem;
    font-size: 12px;
}

.controlDescription {
    font-size: 14px;
    line-height: 135%;
    color: #999;
    font-size: 12px;
    margin-top: 12px;
    margin-bottom: 20px;
}

.controlContainer {
    display: flex;
}

.priceBox {
    background-color: #1c1c1e;
    padding: 1rem;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.priceAmount {
    font-size: 20px;
    font-weight: 700;
}

.priceCurrency {
    margin-left: 0.5rem;
    font-size: 1.125rem;
}

.chanceControls {
    display: flex;
    align-items: center;

}


.decrementButton,
.incrementButton {
    background-color: #0097ec;
    padding: 0.5rem;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;

    width: 24px;
    height: 24px;
    max-width: 40px;
}


.chanceDisplay {
    flex: 1;
    /* background-color: #1c1c1e; */
    margin: 0 8px;
    border-radius: 0.375rem;
    /* margin: 0 0.5rem; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.chanceValue {
    font-size: 16px;
    font-weight: 600;
}

/* Try luck button */
.tryButton {
    font-size: 12px;
    font-weight: 500;
}





.percentSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
}

.percentButtons {
    display: flex;
    gap: 8px;
    align-items: center;
}

.percentButton {
    background: rgb(0, 151, 236, 0.2);
    font-size: 12px;
    padding: 6px 12px;
    border-radius: 4px;
    color: #0097ec;
    height: fit-content;
}