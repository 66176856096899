.error {
    color: #ec0004;
    display: flex;
    align-items: center;
    gap: 12px;

    background: rgb(236, 0, 4, 0.1);
    padding: 12px 16px;
    border-radius: 8px;

    /* margin-top: 20px; */
    font-weight: 300px;
    z-index: 5;
}

.success {
    color: #7ed321;
    display: flex;
    align-items: center;
    gap: 12px;

    background: rgb(126, 211, 33, .1);
    padding: 12px 16px;
    border-radius: 8px;

    /* margin-top: 20px; */
    font-weight: 300px;
    z-index: 5;
}

.disconnect {

    font-size: 12px;
    text-decoration: underline;
    text-align: end;
    cursor: pointer;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}