
.option {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 12px 16px;
    gap: 10px;
    /* border: 1px solid rgba(255, 255, 255, 0.2); */
    border-radius: 8px;
    transition: 0.1s all ease-in-out;
    /* background: rgba(255, 255, 255, 0.1); */
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    position: relative;
}
.option_content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.optionPrices {
    display: flex;
    align-items: center;
    gap: 6px;
}

.option_selected {
    /* border-color: rgba(53, 231, 229, 0.5); */
    /* background: rgba(53, 231, 229, 0.4); */
    box-shadow: 0 0 16px 0 #1c9d85;
    background: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 1);
}


.option_selected::after {
    content: '✓';
    /* border: 3px solid rgb(255, 29, 29); */
    color: rgb(255, 255, 255);
    position: absolute;
    padding: 8px 12px ;
    font-size: 14px;
    font-weight: 700;
    right: 1%;
    bottom: 5%;
    opacity: 1 !important;
 
}

.option_iconContainer {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.15);
    /* background: rgba(0, 146, 192, 0.5); */
    border-radius: 8px;
    flex-shrink: 0;
    margin-bottom: 20px;
    /* background: rgba(53, 231, 229, 0.); */
}

.option_header{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 4px;
    position: relative;
}
.option_description {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    margin-bottom: 10px;
    transition: 0.1s all ease-in-out;
    line-height: 135%;
    text-align: center;
}

.option_description__selected {
    color: rgba(255, 255, 255, 0.9);
}

.option_price__coin {
    display: flex;
    align-items: center;
    gap: 6px;
    background: rgba(255, 255, 255, 0.2);
    width: fit-content;
    padding: 4px 8px;
    border-radius: 4px;
}

.option_price__ton {
    display: flex;
    align-items: center;
    gap: 6px;
    background: rgba(0, 152, 234, 0.5);
    color: #fff;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 4px;
}

.option_buyed{
    background-color: rgba(28, 157, 133, 0.4);
    color: #00ff59;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 4px;
    border-radius: 4px;
    position: absolute;
    left: 105%;
    top: 50%;
    transform: translateY(-50%);
}