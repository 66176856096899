.linkContainer {
    border: 0.50px solid var(--primary-color);
    border-radius: 7px;
    padding: 10px 8px;


    background: #000f18;
    color: var(--primary-color);
    display: flex;
    align-items: center;
    gap: 10px;

    cursor: pointer;
}

.linkIconContainer {
    border-radius: 4px;
    width: 24px;
    height: 24px;
    background: rgba(0, 151, 236, 0.14);

    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
}

.linkIconContainer svg {
    width: 16px;
    height: 16px;
}

.linkTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
}