.card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    transition: 0.3s all ease-in-out;
    cursor: pointer;

    padding: 8px 8px;
    border-radius: 4px;
}

.card:hover{
    background: rgba(255, 255, 255, 0.1);
}

.left {
    display: flex;
    gap: 10px;
    align-items: center;

}

.right {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-shrink: 0;

}

.title {
    font-size: 14px;
    
}

.price {
    font-size: 12px;
    color: #EFA200;
    font-weight: 500;
    display: flex;
    gap: 4px;
    align-items: center;
}

.iconHolder {
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-shrink: 0;
}

.iconHolder img {
    width: 45px;
    height: 45px;
}

.arrow {
    width: 16px;
    height: 16px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.button{
    /* background: var(--primary-color);
    font-size: 12px;
    padding: 2px 4px;
    border */

    background: #ff9800;
    font-size: 12px;
    border-radius: 12px;
    padding: 4px 10px;
    font-weight: 600;
}