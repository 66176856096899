.containerStyle {
    border-top: 1px solid #ff9800;
    background: linear-gradient(180deg, #ff98001c, transparent 50%) !important;
}

.title {
    font-size: 14px;
    font-weight: 600;
}

.description {
    font-size: 12px;
    color: var(--text-secondary-color);
    margin-bottom: 30px;
    line-height: 160%;
}

.iconHolder {
    height: 90px;
    width: 90px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    transform: translateY(-10%);

}

.img {
    width: 72px;
    height: 72px;
    border-radius: 6px;
    z-index: 1;

    transform: translateY(-4%);
}

.primaryButton {
    padding: 10px 12px;
    font-size: 14px;
}

.buttonActive{
    background: #EFA200;
}

.buttonContainer {
    padding: 0 20px;
}


.card {
    display: flex;
    gap: 14px;
    margin-bottom: 40px;
}

.textContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
}



.titleCLS{
    font-size: 12px;
    font-weight: 600;
}