.balanceContainer {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* flex-direction: column; */
    gap: 10px;
    position: relative;


    /* background: rgba(255, 255, 255, 0.05); */
    padding: 8px 0;


    margin-bottom: 20px;
}

.balanceContainer .imageContainer {
    display: flex;
}



@keyframes rotate {
    0% {
        transform: translateY(-25%) translateX(-50%) rotate(0deg);
    }

    50% {
        transform: translateY(-25%) translateX(-50%) rotate(10deg);
    }

    100% {
        transform: translateY(-25%) translateX(-50%) rotate(0deg);
    }
}


.balanceContainer .balance .title {
    font-size: 12px;
    color: #b4b4b4;

}

.balanceContainer .balance .value {
    font-size: 20px;
}

.balanceContainer .balance .value span {
    font-size: 12px;
}


.fastValues {
    display: flex;
    gap: 10px;
    padding: 0 0 6px 0;
    margin-top: 12px;

}

.fastValues .value {
    color: #fff;
    background: rgb(231, 224, 174, 0.2);
    border: 0.6px solid #E7E0AE;
    padding: 4px 8px;
    /* flex: 1; */
    border-radius: 6px;
    font-size: 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    width: fit-content;
    height: fit-content;

}

.withdrawButton {
    position: absolute;
    bottom: 22px;
    display: flex;
    width: calc(100% - 32px);
}

.withdrawButton .button {
    background: #E7E0AE;
    color: #000;
}

.walletAddress {
    color: #fff;
}

.error {
    color: #ec0004;
    display: flex;
    align-items: center;
    gap: 12px;

    background: rgb(236, 0, 4, 0.1);
    padding: 12px 16px;
    border-radius: 8px;

    margin-top: 20px;
    font-weight: 300px;
    z-index: 5;
}

.success {
    color: #7ed321;
    display: flex;
    gap: 12px;

    background: rgb(126, 211, 33, .1);
    padding: 12px 16px;
    border-radius: 8px;

    margin-top: 20px;
    font-weight: 300px;
    z-index: 5;
}

.disconnect {
    color: #b4b4b4;
    font-size: 12px;
    text-decoration: underline;
    margin-top: 4px;
    text-align: end;
    cursor: pointer;
}

.withdrawhs {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
    overflow-y: scroll;
    padding-bottom: 40px;
}

.withdrawhs::-webkit-scrollbar {
    display: none;
}

.totalMined {
    font-size: 13px;
    font-weight: 600;
}


.comissionAlert {
    background: #171717;
    padding: 12px 10px;
    border-radius: 8px;
    margin-top: 20px;
}


.ca_title {
    font-size: 14px;
    margin-bottom: 12px;
    text-align: center;
}

.ca_title span {
    color: #7ed321;
}

.ca_descriptions {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    color: rgba(255, 255, 255, 0.6);
    gap: 4px;
}

.ca_description span {
    color: #0097ec;
    font-weight: 600;

}



.vertical_line {
    height: 100%;
    width: 1px;
    background: rgba(255, 255, 255, 0.6);
}

/* .horizontal_line {
    width: calc(100% - 40px);
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    margin: 0 20px;
} */

.ca_description {
    font-size: 12px;
    padding: 12px 10px;
    background: rgba(6, 6, 6, 0.2);
    border-radius: 4px;
    /* text-align: center; */
    line-height: 130%;
    position: relative;
}

.ca_description:last-child {
    border: 0.5px solid #000;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    padding-left: 12px;
}



.ca_description:first-child {
    border: 0.5px solid #000;
    border-top: 0;
    border-left: 0;
    border-bottom: 0;
    padding-right: 8px;
}

.ca_description svg {
    position: absolute;
    bottom: -6px;
    left: -6px;

}

.ca_footer {
    font-size: 12px;
    padding: 6px 0 4px;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
}


.ca_description.active .prem {
    transform: rotate(-2deg);
    height: 20px;
    text-align: inline-block;
    color: #0097ec;
}

.ca_description.active:last-child {
    background: rgb(0, 150, 237, 0.1);
    border: 0.5px solid #0097ec;
}

.ca_description.active span {
    color: #0097ec;
    font-weight: 600;

}

/* sds */

.ca_description:last-child {

    background: rgb(133, 133, 133, 0.1);

    border: 0.5px solid #858585;
}

.ca_description .prem {
    transform: rotate(-2deg);
    height: 20px;
    text-align: inline-block;
    color: #b4b4b4;
}

.ca_description span {
    color: #b4b4b4;
    font-weight: 600;
}


.withdraw_main {
    margin-top: 20px;
    background: #171717;
    padding: 20px 16px 20px;
    border-radius: 16px;
}

.withdraw_tab {

    overflow-y: scroll;
    scrollbar-color: var(--primary-color) #000;
    scrollbar-width: thin;


    padding-bottom: 100px;
}