.main {
    overflow-y: scroll;
    z-index: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 16px 100px;
    max-height: calc(100vh - 89.4px);
}




.main::-webkit-scrollbar {
    display: none;
}

.header {
    /* text-align: center; */
    font-weight: 400;
    font-size: 20px;
}

/* ---------------------- */

/* .poolsContainer {
    background: var(--bg-secondary-color);
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.poolsContainer .pool {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.poolsContainer .pool .data {
    display: flex;
    align-items: center;
    gap: 6px;
}

.poolsContainer .pool .data .icon {
    width: 32px;
    height: 32px;
    border-radius: 24px;
    background: #fff;
}

.poolsContainer .pool .data .name {

}

.poolsContainer .pool .params {
    display: flex;
    flex: 1;
}

.poolsContainer .pool .params div {
    flex: 1;
}

.poolsContainer .pool .button {
    background: #1F7356;
    padding: 2px 6px;
    border-radius: 4px;
} */

.poolsContainer {
    display: flex;
    gap: 20px;
    align-items: flex-end;
    margin-bottom: 20px;
}



/* --------------------------- */
.requestContainer {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    /* background: rgba(var(--primary-color-rgb), 0.1); */
    background: rgba(255, 255, 255, 0.1);
    padding: 10px 12px 10px;
    border-radius: 8px;
    font-size: 12px;
    transition: 0.3s all ease-in-out;

}

.req_reject {
    border: 0.5px solid rgba(255, 73, 73, 1);

}

.req_waiting {
    border: 0.5px solid #0097ec;

}


.requestContainer .icon {
    width: 24px;
    height: 24px;
    /* background: rgb(0, 151, 236, 0.2); */
    color: var(--primary-color);
    flex-shrink: 0;
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;

}

.requestContainer .icon.rejected {
    color: rgba(255, 73, 73, 0.952);
    background: rgba(255, 73, 73, 0.200);
    transform: translateY(-2px);
}

.requestContainer .content {
    font-size: 12px;
    /* color: var(--primary-color); */
    color: #b4b4b4;
    flex: 1;

    /* background: rgba(var(--primary-color-rgb), 0.1); */
    /* padding: 10px 12px; */
    /* border-radius: 4px; */
    line-height: 1.6;
}

.requestContainer .content .title {
    font-size: 14px;
    color: #fff;
}

.requestContainer .content .cancelButton {
    font-size: 12px;
    padding: 10px 12px;
    /* width: fit-content; */
    margin-top: 10px;
    background: rgb(0, 151, 236, 0.1);
    color: #0097ec;

}

/* --------------------------- */

.hashContainer {
    background: var(--bg-secondary-color);
    padding: 20px 16px 32px;
    line-height: 1.6;
    border-radius: 8px;
    text-align: center;
    min-height: 215px;
}

.hashName {}

.hashTime {
    margin-bottom: 20px;
    font-size: 12px;
}

.hash {
    word-break: break-all;
    font-size: 12px;
    color: var(--text-secondary-color);
    transform: translateY(10px);
    opacity: 0;
    animation: hashView 0.5s ease-out forwards;


}

@keyframes hashView {
    to {
        transform: translateY(0);
        opacity: 1;
        position: relative;
    }
}

.confirmBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.confirmBlock__boosts {
    display: flex;
    gap: 20px;
}

/* --------------------------- */


.blocksContainer {
    background: var(--bg-secondary-color);
    border-radius: 8px;
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
}

.blocksContainer .header {
    margin-bottom: 20px;
    text-align: center;
}

.blocks {
    display: flex;
    flex-direction: column;
    gap: 12px;
}




.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.blocks {
    opacity: 1;
    transition: 0.3s all ease-in-out;
}

.pagTitle {
    font-size: 12px;
    color: #b4b4b4;
}

.pagButtons {
    width: 40px !important;
    height: 40px !important;
}

.blocksLoading {
    opacity: 0;
}