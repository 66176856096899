.subHeader {
    font-size: 16px;
    margin: 20px 0 6px;
}

.miners {
    background: var(--bg-secondary-color);
    border-radius: 8px;
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.miner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nameWithPosition {
    display: flex;
    align-items: center;
    gap: 6px;
}

.nameWithPosition .position {
    font-size: 12px;
    color: var(--text-secondary-color);
}


.nameWithPosition .name {
    font-size: 12px;
    color: var(--text-secondary-color);
}

.miner .ths {
    font-size: 12px;
    padding: 0 8px 0 0;
    color: var(--text-secondary-color);
    text-align: right;
    flex: 1;
}

.premiumIconOutline {
    color: #0097ec;
}

.premiumIcon {
    position: relative;
    width: 16px;
    height: 16px;
}

.premiumIcon .bg {
    width: 100%;
    height: 100%;
    color: var(--primary-color);

}

.premiumIcon .notActiveBg {
    color: #b4b4b4 !important;
}

.premiumIcon .notActiveContent {
    color: var(--bg-secondary-color);
}

.premiumIcon .content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    transform: translateX(-50%) translateY(-50%);

}

.rejectAll {
    color: #ff1418;
    background: rgba(255, 20, 24, 0.16);
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}